/* 储存考核工具kpi、bpi等数据的store */
import { defineStore } from 'pinia';

const useAssessmentToolStore = defineStore('asessmentTools', {
  state: () => ({
    calculate: 2, // 考核评分计算 1 加和 2加权， 默认2加权,
    upperLimit: 1, // 是否设置了评分上限
    upperScore: -1, // 评分上限分值，当用户选了评分上限才有，
    currId: 0 // 储存选中数据的id，默认为0
  }),
  getters: {
    // 返回考核评分计算
    getCalculate(state): number {
      return state.calculate;
    },
    // 返回评分上限规则
    geUpperLimit(state) {
      return state.upperLimit;
    },
    // 返回评分上限分值
    getUpperScore(state) {
      return state.upperScore;
    },
    // 获取当前选中的id
    getCurrId(state) {
      return state.currId;
    }
  },

  actions: {
    // 储存自定义表单表单的小标题
    setRules(upperLimit: number, calculate: number, upperScore?: number) {
      this.upperLimit = upperLimit;
      this.calculate = calculate;
      if (upperScore) this.upperScore = upperScore;
    },
    // 设置当前选中的id
    setId(id: number) {
      this.currId = id;
    }
  }
});

export default useAssessmentToolStore;
