/* 储存组织模块-基础信息里面的相关子路由（不显示在侧边导航栏的路由） */

const organizationalBaseSettingSubRoutes: any = [
  /* 组织类型 */
  {
    path: 'organizationalType',
    name: 'OrganizationalType',
    component: () =>
      import(
        '@/views/Management/Organization/BaseSetting/OrganizationalType/index.vue'
      ),
    meta: {
      locale: '组织类型',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  /* 组织信息模板库 */
  {
    path: 'organizationTemplate',
    name: 'OrganizationTemplate',
    component: () =>
      import(
        '@/views/Management/Organization/BaseSetting/OrganizationTemplate/index.vue'
      ),
    meta: {
      locale: '组织类型模板',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  /* 组织类型模板 */
  {
    path: 'organizationSettingTemplate',
    name: 'OrganizationSettingTemplate',
    component: () =>
      import(
        '@/views/Management/Organization/BaseSetting/OrganizationalType/SettingTemplate/index.vue'
      ),
    meta: {
      locale: '组织信息模板库',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  /* 法律实体 */
  {
    path: 'LegalEntityType',
    name: 'LegalEntityType',
    component: () =>
      import(
        '@/views/Management/Organization/BaseSetting/LegalEntityType/index'
      ),
    meta: {
      locale: '法律实体',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  /* 控编维度 */
  {
    path: 'ManagementControl',
    name: 'ManagementControl',
    component: () =>
      import(
        '@/views/Management/Organization/BaseSetting/ManagementControl/index.vue'
      ),
    meta: {
      locale: '控编维度',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  /* 工作地点 */
  {
    path: 'WordPlace',
    name: 'WordPlace',
    component: () =>
      import('@/views/Management/Organization/BaseSetting/WordPlace/index'),
    meta: {
      locale: '工作地点',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  /* 导入花名册 */
  {
    path: 'EditRoster',
    name: 'EditRoster',
    component: () =>
      import('@/views/Management/Organization/BaseSetting/EditRoster/index'),
    meta: {
      locale: '导入花名册设置',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  /* 职位类别 */
  {
    path: 'PositionSort',
    name: 'PositionSort',
    component: () =>
      import(
        '@/views/Management/Organization/BaseSetting/PositionSystem/PositionSort/index.vue'
      ),
    meta: {
      locale: '职位类别',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  /* 职位类型模板 */
  {
    path: '/positionSettingTemplate',
    name: 'PositionSettingTemplate',
    component: () =>
      import(
        '@/views/Management/Organization/BaseSetting/PositionSystem/PositionSort/SettingTemplate/index.vue'
      ),
    meta: {
      locale: '职位类型模板',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  /* 职位说明书模板库 */
  {
    path: '/positionTemplate',
    name: 'PositionTemplate',
    component: () =>
      import(
        '@/views/Management/Organization/BaseSetting/PositionSystem/PositionSettingTemplate/index.vue'
      ),
    meta: {
      locale: '职位说明书模板',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  /* 职位层级 */
  {
    path: 'PositionHierarchy',
    name: 'PositionHierarchy',
    component: () =>
      import(
        '@/views/Management/Organization/BaseSetting/PositionSystem/PositionHierarchy/index'
      ),
    meta: {
      locale: '职位层级',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  /* 职务级别 */
  {
    path: 'JobLevel',
    name: 'JobLevel',
    component: () =>
      import(
        '@/views/Management/Organization/BaseSetting/PositionSystem/JobLevel/index'
      ),
    meta: {
      locale: '职务级别',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  /* 职务类别*/
  {
    path: 'JobSort',
    name: 'JobSort',
    component: () =>
      import(
        '@/views/Management/Organization/BaseSetting/PositionSystem/JobSort/index'
      ),
    meta: {
      locale: '职务职务类别级别',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  }
];

export default organizationalBaseSettingSubRoutes;
