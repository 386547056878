// 平台开放接口
import { ajaxService } from '@/utils/request';
import type { LoginType, TenantParams, LoginBgImg } from './type';

const env = import.meta.env;
const baseURL = env.VITE_APP_PLAT_URL;

/**
 * 根据租户编码获取平台租户列表
 * @param params
 * @returns
 */
export async function getPlatformClientList(params: { p: any }) {
  return ajaxService<any>({
    url: '/platform_management/open_api/client/list',
    method: 'get',
    baseURL,
    params
  });
}
/**
 * 获取业务功能所属的控件信息
 * @param params
 * @returns
 */
export async function getPlatformCtl(params: { funcCode: string }) {
  return ajaxService<any>({
    url: '/platform_ccc/cccApi/func/ctl',
    method: 'get',
    baseURL,
    params
  });
}
/**
 * 验证登录
 * @param data
 * @returns
 */
export async function VerifyLogin(data: LoginType) {
  return ajaxService<any>({
    url: '/platform_inlet/sso/verifier/login',
    method: 'post',
    baseURL,
    data,
    noToken: true
  });
}
/**
 * 更换租户
 * @param data
 * @returns
 */
export async function TenantChange(data: TenantParams) {
  return ajaxService<any>({
    url: '/platform_inlet/sso/cut/tenant',
    method: 'put',
    baseURL,
    data
  });
}
/**
 * 根据租户code和用户code获取token
 * @param params
 * @returns
 */
export async function getTokenByUcodeWithCcode(params: TenantParams) {
  return ajaxService<any>({
    url: 'platform_inlet/sso/access/token',
    method: 'get',
    baseURL,
    params
  });
}

/**
 * 获取指定登录页背景图片
 * @param domain
 * @returns
 */
export async function getLoginBgImg(domain: string) {
  return ajaxService<any>({
    url: `/platform_inlet/login/page/settings/${domain}`,
    method: 'get',
    baseURL,
    noToken: true
  });
}
