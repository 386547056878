/* 储存自定义表单相关store */
import { defineStore } from 'pinia';

const useSetUpFormStore = defineStore('setUpForm', {
  state: () => ({
    // 存放当前模板子信息的标题
    tabList: <any>[
      {
        name: '基础信息',
        key: 0,
        isEdit: false,
        sort: 0,
        collectionId: undefined
      },
      {
        name: '新增',
        key: -1,
        isEdit: false,
        sort: -1
      }
    ],
    templateId: undefined, // 储存新增模板时返回的templateId

    /* 区分post是哪个模板 
      1. 组织类型
      2. 职位类型
    */
    templateType: 0,
    delCollectionsIds: <number[]>[], // 储存模板编辑时要删除的子集id
    delControlIds: <number[]>[] // 储存模板编辑时要删除的控件id
  }),
  getters: {
    // 返回自定义表单小标题数据
    getTabList(state) {
      return state.tabList.values;
    },
    getCollectionsIds(): number[] {
      return this.delCollectionsIds;
    },
    getControlIds(): number[] {
      return this.delControlIds;
    }
  },

  actions: {
    // 储存自定义表单表单的小标题
    setTabList(list: any) {
      this.tabList.values = list;
    },

    // 新增要删除的子集id
    addCollectionsIds(id: number) {
      this.delCollectionsIds.push(id);
    },
    // 新增要删除的控件id
    addControlIds(id: number) {
      this.delControlIds.push(id);
    }
  }
});

export default useSetUpFormStore;
