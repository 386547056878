/*
 * @Author: 陈俊升 761723995@qq.com
 * @Date: 2023-11-07 17:03:19
 * @LastEditors: 陈俊升 761723995@qq.com
 * @LastEditTime: 2023-12-13 14:17:11
 * @FilePath: /hr_new_vue3/src/router/routes/management/contract/contractManagement/index.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD
 */
/* 储存合同模块-合同管理里面的相关子路由（不显示在侧边导航栏的路由） */

const contractManagementIndexSubRoutes: any = [
  {
    path: 'contractDraft',
    name: 'ContractDraft',
    component: () =>
      import(
        '@/views/Management/Contract/ContractCenter/WaitSigned/Components/DraftContract/index.vue'
      ),
    meta: {
      locale: '起草合同',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'ContractDetails',
    name: 'ContractDetails',
    component: () =>
      import('@/views/Management/Contract/ContractDetails/index.vue'),
    meta: {
      locale: '合同详情',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'ContractDetailsSecond',
    name: 'ContractDetailsSecond',
    component: () =>
      import('@/views/Management/Contract/ContractDetailsSecond/index.vue'),
    meta: {
      locale: '合同详情',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'EditContract',
    name: 'EditContract',
    component: () =>
      import('@/views/Management/Contract/EditContract/index.vue'),
    meta: {
      locale: '修改合同',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'contractDraftEdit',
    name: 'ContractDraftEdit',
    component: () =>
      import(
        '@/views/Management/Contract/ContractCenter/WaitSigned/Components/DraftContractEdit/index.vue'
      ),
    meta: {
      locale: '编辑起草合同',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'comfirmSignContract',
    name: 'ComfirmSignContract',
    component: () =>
      import(
        '@/views/Management/Contract/ContractCenter/WaitSigned/Components/ComfirmSignContract/index.vue'
      ),
    meta: {
      locale: '确认签署合同',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'contractChange',
    name: 'ContractChange',
    component: () =>
      import(
        '@/views/Management/Contract/ContractCenter/HaveSigned/Components/ContractChange/index.vue'
      ),
    meta: {
      locale: '合同变更',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'contractRenewalLetterSend',
    name: 'ContractRenewalLetterSend',
    component: () =>
      import(
        '@/views/Management/Contract/ContractCenter/WaitSigned/Components/ContractRenewalLetter/index.vue'
      ),
    // component: () =>
    //   import(
    //     '@/views/Management/Contract/ContractCenter/WaitSigned/Components/DraftContract/indexCopy.vue'
    //   ),
    meta: {
      locale: '发起合同续签意向表',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'UserContractList',
    name: 'UserContractList',
    component: () =>
      import(
        '@/views/Management/Contract/ContractCenter/Files/UserContractList/index.vue'
      ),
    meta: {
      locale: '人员合同档案详情',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'contractDetail',
    name: 'ContractDetail',
    component: () =>
      import(
        '@/views/Management/Contract/ContractCenter/Files/Components/ContractDetail/index.vue'
      ),
    meta: {
      locale: '合同档案详情',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'renewalCommunicate',
    name: 'RenewalCommunicate',
    component: () =>
      import(
        '@/views/Management/Contract/ContractList/Renewal/RenewalCommunicate/index.vue'
      ),
    meta: {
      locale: '续签沟通',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  }
];

export default contractManagementIndexSubRoutes;
