//数据库封装函数
//打开数据库
export function openDb(
  dbName: string,
  version: number,
  upDataCallBack: (db: IDBDatabase) => void
) {
  return new Promise<IDBDatabase>((resolve, reject) => {
    const indexedDB = window.indexedDB;
    const request = indexedDB.open(dbName, version);
    request.onsuccess = function (event: any) {
      resolve(event.target.result);
    };
    // 数据库打开失败的回调
    request.onerror = function () {
      reject('数据库打开失败');
    };
    // 数据库有更新时候的回调
    request.onupgradeneeded = function (event: any) {
      const db = event.target.result;
      upDataCallBack(db);
    };
  });
}

//数据库新增数据
export function addDbData(db: IDBDatabase, storeName: string, data: any) {
  return new Promise((resolve, reject) => {
    const request = db
      .transaction([storeName], 'readwrite')
      .objectStore(storeName)
      .add(data);
    request.onsuccess = function () {
      //数据写入成功
      resolve('数据写入成功');
    };
    request.onerror = function (err: any) {
      //数据写入失败
      console.log('数据写入失败');
      reject(err);
    };
  });
}

//通过主键获取数据
export function getDbDataByKey<T>(
  db: IDBDatabase,
  storeName: string,
  key: any
) {
  return new Promise<T>((resolve, reject) => {
    const request = db
      .transaction([storeName], 'readwrite')
      .objectStore(storeName)
      .get(key); // 通过主键获取数据
    request.onsuccess = function () {
      resolve(request.result);
    };

    request.onerror = function () {
      reject('获取数据失败');
    };
  });
}

//通过主键删除数据
export function delDbDataByKey(db: IDBDatabase, storeName: string, key: any) {
  return new Promise((resolve, reject) => {
    const request = db
      .transaction([storeName], 'readwrite')
      .objectStore(storeName)
      .delete(key);

    request.onsuccess = function () {
      resolve('数据删除成功');
    };

    request.onerror = function () {
      reject('数据删除失败');
    };
  });
}

//通过主键获取全部数据
export function getAllDbData(objectStore: IDBObjectStore) {
  return new Promise((resolve, reject) => {
    const request = objectStore.getAll();

    request.onsuccess = function () {
      resolve('');
    };

    request.onerror = function () {
      reject('');
    };
  });
}
