// import axios from 'axios';
import type { RouteRecordNormalized } from 'vue-router';
import { UserState } from '@/store/modules/base/user/types';
import { ajaxService } from '@/utils/request';

const env = import.meta.env;
const baseURL = env.VITE_APP_BASE_URL;
const baseURL1 = env.VITE_APP_BASE_URL;

// const baseURL = 'http://192.168.0.178:39001';

// const baseURL = env.VITE_APP_BASE_URL;

export interface LoginData {
  mobile: string;
  password: string;
  urlPrefix: string; // 用户ip，用来区分租户
}

export interface LoginRes {
  token: string;
}
// export function login(data: LoginData) {
//   return axios.post<LoginRes>('http://192.168.31.129:9001/user/login', data);
// }

// #VITE_APP_CUSTOM_TAOGE_URL="http://192.168.31.129:8081"
// #VITE_APP_CUSTOM_LIANGGE_URL="http://192.168.31.129"
// #VITE_APP_CUSTOM_HONGGE_URL="http://192.168.0.120:9001"

/* 登录接口 */
export function login(data: LoginData) {
  return ajaxService({
    url: '/user/login',
    method: 'post',
    data,
    noToken: true,
    baseURL
  });
}

/* 用户信息 */
export function personalInfo() {
  return ajaxService({
    url: '/hr/workbench/personal_settings/personal/info',
    method: 'get',
    baseURL: baseURL1
  });
}

/* 用户信息 */
export function putPersonalInfo(data: any) {
  return ajaxService({
    url: '/hr/workbench/personal_settings/personal/update',
    method: 'put',
    data,
    baseURL: baseURL1
  });
}

// export function logout() {
//   return axios.post<LoginRes>('/api/user/logout');
// }

// export function getUserInfo() {
//   return axios.post<UserState>('/api/user/info');
// }

// export function getMenuList() {
//   return axios.post<RouteRecordNormalized[]>('/api/user/menu');
// }
