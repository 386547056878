/* 存放考勤相关路由 */

import { DEFAULT_LAYOUT } from '../base';
// import DEFAULT_LAYOUT from '@/layout/default-layout.vue'
import { AppRouteRecordRaw } from '../types';
import attendanceBaseSettingIndexSubRoutes from './attendance/baseSetting';
import attendanceManagementSubRoutes from './attendance/attendanceManagement';

import homeSub from './homeSub';

const Attendance: AppRouteRecordRaw = {
  path: '/attendance',
  name: 'Attendance',
  component: DEFAULT_LAYOUT,
  meta: {
    locale: '考勤',
    requiresAuth: true,
    icon: 'icon-home',
    order: 3,
    hideInMenu: false,
    key: '4',
    roles: ['admin']
  },
  redirect: {
    name: 'AttendanceBaseSetting'
  },
  children: [
    ...homeSub,
    {
      path: 'attendanceBaseSetting',
      name: 'AttendanceBaseSetting',
      component: () =>
        import('@/views/Management/Attendance/BaseSetting/index.vue'),
      meta: {
        locale: '基础设置',
        requiresAuth: true,
        icon: 'icon-jichushezhi',
        roles: ['*'],
        hideInMenu: false
      }
    },
    {
      path: 'attendanceManagement',
      name: 'AttendanceManagement',
      meta: {
        locale: '考勤管理',
        requiresAuth: true,
        icon: 'icon-writing',
        roles: ['*'],
        hideInMenu: false
      },
      children: [
        // {
        //   path: 'AttendanceManagementWorkCalendar',
        //   name: 'attendanceManagementWorkCalendar',
        //   component: () =>
        //     import(
        //       '@/views/Management/Attendance/AttendanceManagement/WorkCalendar/index.vue'
        //     ),
        //   meta: {
        //     locale: '工作日历',
        //     requiresAuth: true,
        //     icon: '',
        //     roles: ['*'],
        //     hideInMenu: false
        //   }
        // },
        {
          path: 'attendanceManagementAttendanceSetting',
          name: 'AttendanceManagementAttendanceSetting',
          component: () =>
            import(
              '@/views/Management/Attendance/AttendanceManagement/AttendanceSetting/index.vue'
            ),
          meta: {
            locale: '考勤设定',
            requiresAuth: true,
            // icon: 'icon-jichushezhi',
            roles: ['*'],
            hideInMenu: false
          }
        },
        {
          path: 'AttendanceManagementAttendanceGroup',
          name: 'attendanceManagementAttendanceGroup',
          component: () =>
            import(
              '@/views/Management/Attendance/AttendanceManagement/AttendanceGroup/index.vue'
            ),
          meta: {
            locale: '考勤组管理',
            requiresAuth: true,
            // icon: 'icon-jichushezhi',
            roles: ['*'],
            hideInMenu: false
          }
        },
        {
          path: 'AttendanceManagementClassesManagement',
          name: 'attendanceManagementClassesManagement',
          component: () =>
            import(
              '@/views/Management/Attendance/AttendanceManagement/ClassesManagement/index.vue'
            ),
          meta: {
            locale: '班次管理',
            requiresAuth: true,
            // icon: 'icon-jichushezhi',
            roles: ['*'],
            hideInMenu: false
          }
        },
        {
          path: 'AttendanceManagementScheduleManagement',
          name: 'attendanceManagementScheduleManagement',
          component: () =>
            import(
              '@/views/Management/Attendance/AttendanceManagement/ScheduleManagement/index.vue'
            ),
          meta: {
            locale: '排班管理',
            requiresAuth: true,
            // icon: 'icon-jichushezhi',
            roles: ['*'],
            hideInMenu: false
          }
        },
        {
          path: 'AttendanceManagementAttendanceRules',
          name: 'attendanceManagementAttendanceRules',
          component: () =>
            import(
              '@/views/Management/Attendance/AttendanceManagement/AttendanceRules/index.vue'
            ),
          meta: {
            locale: '考勤规则',
            requiresAuth: true,
            // icon: 'icon-jichushezhi',
            roles: ['*'],
            hideInMenu: false
          }
        },
        // {
        //   path: 'AttendanceManagementPunchingMode',
        //   name: 'attendanceManagementPunchingMode',
        //   component: () =>
        //     import(
        //       '@/views/Management/Attendance/AttendanceManagement/PunchingMode/index.vue'
        //     ),
        //   meta: {
        //     locale: '打卡方式',
        //     requiresAuth: true,
        //     // icon: 'icon-jichushezhi',
        //     roles: ['*'],
        //     hideInMenu: false
        //   }
        // },
        {
          path: 'AttendanceManagementAttendanceRecord',
          name: 'attendanceManagementAttendanceRecord',
          component: () =>
            import(
              '@/views/Management/Attendance/AttendanceManagement/AttendanceRecord/index.vue'
            ),
          meta: {
            locale: '考勤记录',
            requiresAuth: true,
            // icon: 'icon-jichushezhi',
            roles: ['*'],
            hideInMenu: false
          }
        }
      ]
    },
    {
      path: 'HolidayManagement',
      name: 'holidayManagement',
      meta: {
        locale: '假期管理',
        requiresAuth: true,
        icon: 'icon-a-3Dhuichang',
        roles: ['*'],
        hideInMenu: false
      },
      children: [
        // {
        //   path: 'HolidayManagementHolidayOverview',
        //   name: 'holidayManagementHolidayOverview',
        //   component: () =>
        //     import(
        //       '@/views/Management/Attendance/HolidayManagement/HolidayOverview/index.vue'
        //     ),
        //   meta: {
        //     locale: '休假概览',
        //     requiresAuth: true,
        //     // icon: 'icon-jichushezhi',
        //     roles: ['*'],
        //     hideInMenu: false
        //   }
        // },
        {
          path: 'HolidayManagementHolidayBalance',
          name: 'holidayManagementHolidayBalance',
          component: () =>
            import(
              '@/views/Management/Attendance/HolidayManagement/HolidayBalance/index.vue'
            ),
          meta: {
            locale: '假期统计',
            requiresAuth: true,
            // icon: 'icon-jichushezhi',
            roles: ['*'],
            hideInMenu: false
          }
        },
        {
          path: 'HolidayManagementHolidayCustom',
          name: 'holidayManagementHolidayCustom',
          component: () =>
            import(
              '@/views/Management/Attendance/HolidayManagement/HolidayCustom/index.vue'
            ),
          meta: {
            locale: '调整记录',
            requiresAuth: true,
            // icon: 'icon-jichushezhi',
            roles: ['*'],
            hideInMenu: false
          }
        },
        {
          path: 'HolidayManagementHolidayRules',
          name: 'holidayManagementHolidayRules',
          component: () =>
            import(
              '@/views/Management/Attendance/HolidayManagement/HolidayRules/index.vue'
            ),
          meta: {
            locale: '假期规则',
            requiresAuth: true,
            // icon: 'icon-jichushezhi',
            roles: ['*'],
            hideInMenu: false
          }
        }
      ]
    },
    {
      path: 'AttendanceStatistics',
      name: 'attendanceStatistics',
      meta: {
        locale: '考勤统计',
        requiresAuth: true,
        icon: 'icon-a-3Dhuichang',
        roles: ['*'],
        hideInMenu: false
      },
      children: [
        {
          path: 'AttendanceDaily',
          name: 'attendanceDaily',
          component: () =>
            import(
              '@/views/Management/Attendance/AttendanceStatistics/AttendanceDaily/index.vue'
            ),
          meta: {
            locale: '考勤日报',
            requiresAuth: true,
            // icon: 'icon-jichushezhi',
            roles: ['*'],
            hideInMenu: false
          }
        },
        {
          path: 'AttendanceMonthly',
          name: 'attendanceMonthly',
          component: () =>
            import(
              '@/views/Management/Attendance/AttendanceStatistics/AttendanceMonthly/index.vue'
            ),
          meta: {
            locale: '考勤月报',
            requiresAuth: true,
            // icon: 'icon-jichushezhi',
            roles: ['*'],
            hideInMenu: false
          }
        },
        {
          path: 'AttendanceDetail',
          name: 'attendanceDetail',
          component: () =>
            import(
              '@/views/Management/Attendance/AttendanceStatistics/AttendanceDetail/index.vue'
            ),
          meta: {
            locale: '考勤明细',
            requiresAuth: true,
            // icon: 'icon-jichushezhi',
            roles: ['*'],
            hideInMenu: false
          }
        },
      ]
    },
    ...attendanceBaseSettingIndexSubRoutes,
    ...attendanceManagementSubRoutes
  ]
};

export default Attendance;
