/* o */
import { defineStore } from 'pinia';

const useFlowStore = defineStore('order', {
  state: () => {
    return {
      tableId: '',
      isTried: false,
      promoterDrawer: false,
      promoterConfig1: {},
      flowPermission1: {},
      approverDrawer: false,
      approverConfig1: {},
      copyerDrawer: false,
      copyerConfig1: {},
      conditionDrawer: false,
      conditionsConfig1: {
        conditionNodes: []
      }
    };
  },

  actions: {
    setTableId(payload: any) {
      this.tableId = this.handlePayload(payload);
    },
    setIsTried(payload: any) {
      this.isTried = this.handlePayload(payload);
    },
    setPromoter(payload: any) {
      this.promoterDrawer = this.handlePayload(payload);
    },
    setPromoterConfig(payload: any) {
      this.promoterConfig1 = this.handlePayload(payload);
    },
    setFlowPermission(payload: any) {
      this.flowPermission1 = this.handlePayload(payload);
    },
    setApprover(payload: any) {
      this.approverDrawer = this.handlePayload(payload);
    },
    setApproverConfig(payload: any) {
      this.approverConfig1 = this.handlePayload(payload);
    },
    setCopyer(payload: any) {
      this.copyerDrawer = this.handlePayload(payload);
    },
    setCopyerConfig(payload: any) {
      this.copyerConfig1 = this.handlePayload(payload);
      console.log(this.copyerConfig1);
    },
    setCondition(payload: any) {
      this.conditionDrawer = this.handlePayload(payload);
    },
    setConditionsConfig(payload: any) {
      this.conditionsConfig1 = this.handlePayload(payload);
    },
    handlePayload(data: any) {
      data?.participantList?.forEach((item: any, index: number) => {
        // 用于人员弹窗显示，人员弹窗显示的字段名和id名与后台返回的字段名和id名不同，需要进行转换
        item.name = item.objName;
        item.id = item.objId;
        item.type = item.objType;
        item.orderNo = index + 1;
      });
      return data;
    }
  }
});

export default useFlowStore;
