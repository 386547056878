import { defineStore } from 'pinia';
// import {
//   login as userLogin,
//   logout as userLogout,
//   getUserInfo
// } from '@/apis/base/user';
import { personalInfo } from '@/apis/base/user/index';

// import { Getsingleperson } from '@/api/home/System settings/personal-information/index';
import HOME from '@/router/routes/management/home';
import HUMAN from '@/router/routes/management/human';
import { setToken, clearToken } from '@/utils/auth';
import { removeRouteListener } from '@/utils/route-listener';
import { RoleType, UserState } from './types';
import useAppStore from '../app';
import router from '@/router';
import {
  VerifyLogin,
  TenantChange,
  getTokenByUcodeWithCcode
} from '@/apis/platform/index';

const useUserStore = defineStore('user', {
  state: (): UserState => ({
    headPortrait: '',
    personalHeadPortrait: '',
    name: undefined,
    avatar: undefined,
    job: undefined,
    organization: undefined,
    location: undefined,
    email: undefined,
    introduction: undefined,
    personalWebsite: undefined,
    jobName: undefined,
    organizationName: undefined,
    locationName: undefined,
    phone: undefined,
    registrationDate: undefined,
    accountId: undefined,
    certification: undefined,
    role: '',
    uCode: ''
  }),

  getters: {
    userInfo(state: UserState): UserState {
      return { ...state };
    }
  },

  actions: {
    switchRoles() {
      return new Promise((resolve) => {
        localStorage.setItem(
          'userRole',
          localStorage.getItem('userRole') === 'user' ? 'admin' : 'user'
        );
        this.role = localStorage.getItem('userRole') as RoleType;
        // this.role = this.role === 'user' ? 'admin' : 'user';
        if (this.role === 'admin') {
          sessionStorage.setItem('currMenus', JSON.stringify(HOME));
        }
        resolve(this.role);
      });
    },
    // Set user's information
    setInfo(partial: Partial<UserState>) {
      this.$patch(partial);
    },

    // Reset user's information
    resetInfo() {
      this.$reset();
    },

    // Get user's information
    async info() {
      // const res = await getUserInfo();
      // this.setInfo(res.data);
    },

    // Login
    /* 
      loginForm 账号密码
      authority 权限  admin管理员 user员工
    */

    async login(cCode: any, uCode: any, authority: RoleType) {
      try {
        // 请求登录接口
        const res: any = await getTokenByUcodeWithCcode({
          cCode,
          uCode
        });
        setToken(res.accessToken); // 储存token到localStorage
        sessionStorage.setItem('currMenus', JSON.stringify(HOME)); // 每次登录将首页的路由菜单存放到
        this.role = authority;
        localStorage.setItem('userRole', authority); // 存放权限
        localStorage.setItem('userInfo', JSON.stringify(res.userInfo)); // 存放登录人员的数据
        // localStorage.setItem('roleId', 1); // 存放登录人员的数据
        localStorage.setItem('userId', res.userInfo.id); // 存放登录人员id
        const { headPortrait, personalHeadPortrait } = await personalInfo();
        this.headPortrait = headPortrait;
        this.personalHeadPortrait = personalHeadPortrait;
        localStorage.setItem('personalHeadPortrait', personalHeadPortrait);
        localStorage.setItem('headPortrait', headPortrait);
        // const msg = await Getsingleperson({ id: res.userId });
        // localStorage.setItem('loginInfo', JSON.stringify(msg)); // 登录后将登录人信息存储到localStorage
      } catch (err) {
        clearToken();
        // throw err;
        return Promise.reject(err);
      }
    },

    // async login(loginForm: any, authority: RoleType) {
    //   try {
    //     console.log('loginForm', loginForm);

    //     // 请求登录接口
    //     const res: any = await userLogin({
    //       mobile: loginForm.username,
    //       password: loginForm.password,
    //       urlPrefix: '192.168.31.87:8080'
    //     });
    //     setToken(res.access_token); // 储存token到localStorage
    //     sessionStorage.setItem('currMenus', JSON.stringify(HOME)); // 每次登录将首页的路由菜单存放到
    //     this.role = authority;
    //     localStorage.setItem('userRole', authority); // 存放权限
    //     localStorage.setItem('userInfo', JSON.stringify(res)); // 存放登录人员的数据
    //     localStorage.setItem('userId', res.userStaffId); // 存放登录人员id
    //     // const msg = await Getsingleperson({ id: res.userId });
    //     // localStorage.setItem('loginInfo', JSON.stringify(msg)); // 登录后将登录人信息存储到localStorage
    //   } catch (err) {
    //     clearToken();
    //     // throw err;
    //     return Promise.reject(err);
    //   }
    // },

    /**
     * 切换租户
     * @param cCode
     * @param uCode
     * @returns
     */
    async TenantSelect(cCode: string, uCode: string, authority: RoleType) {
      const res = await TenantChange({ cCode, uCode });
      setToken(res.accessToken); // 储存token到localStorage
      sessionStorage.setItem('currMenus', JSON.stringify(HOME)); // 每次登录将首页的路由菜单存放到
      this.role = authority;
      localStorage.setItem('userRole', authority); // 存放权限
      localStorage.setItem('userInfo', JSON.stringify(res.userInfo)); // 存放登录人员的数据
      localStorage.setItem('userId', res.userInfo.id); // 存放登录人员id
    },
    logoutCallBack() {
      const appStore = useAppStore();
      this.resetInfo();
      clearToken();
      removeRouteListener();
      appStore.clearServerMenu();
    },
    // Logout
    async logout() {
      try {
        // await userLogout();
      } finally {
        this.logoutCallBack();
      }
    }
  }
});

export default useUserStore;
