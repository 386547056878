import { SetTiming, GetTiming } from './localStorageTiming';

//保存token
export const localSetToken = (token: string) => {
  localStorage.setItem('AccountToken', token);
};
//获取token
export const localGetToken = () => {
  return localStorage.getItem('AccountToken');
};

//保存用户账号信息
export const localSetUserInfo = (userInfo: any) => {
  const data = userInfo ? JSON.stringify(userInfo) : '';
  localStorage.setItem('userInfo', data);
};
//获取用户账号信息
export const localGetUserInfo = () => {
  const accountInfo = localStorage.getItem('userInfo');
  return accountInfo ? JSON.parse(accountInfo) : '';
};

//获取文件上传列表
export const localGetUploadFileList = (): any[] => {
  return GetTiming('UploadFileList');
};

//保存文件上传列表
export const localSetUploadFileList = (uploadFileList: any[]) => {
  SetTiming('UploadFileList', uploadFileList, 60 * 60 * 24);
};
