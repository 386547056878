import { defineStore } from 'pinia';
import type { TenantParams } from '@/apis/platform/type';
import { getPlatformClientList, TenantChange } from '@/apis/platform/index';

const useTenantStore = defineStore('tenant', {
  state: () => {
    return {
      tenantList: [], //租户列表
      currentTenantCode: '',
      tenantCodeList: ''
    };
  },
  getters: {
    getTenant(): any {
      return JSON.parse(localStorage.getItem('tenants') as any);
    },
    getCurrentTenantCode(): any {
      return this.currentTenantCode || localStorage.getItem('tenantCode');
    },
    getTenantCodeList(): any {
      return JSON.parse(localStorage.getItem('tenantCodeList') as any);
    }
  },
  actions: {
    /**
     * 设置租户列表
     * @param tenantList
     */
    setTenant(tenantList: any) {
      this.tenantList = tenantList;
    },
    /**
     * 设置当前选择的租户code
     * @param code
     */
    setCurrentTenantCode(code: any) {
      this.currentTenantCode = code;
      localStorage.setItem('tenantCode', code);
    },
    /**
     * 设置当前租户数据
     * @param data
     */
    setTenantCode(data: any) {
      this.tenantCodeList = data;
      localStorage.setItem('tenantCodeList', JSON.stringify(data));
    },

    /**
     * 根据租户code获取租户列表
     * @param strCode
     * @returns
     */
    async getTenantListByCode(strCode: string) {
      const res = await getPlatformClientList({ p: strCode });
      if (!res.cInfoList) return;
      this.setTenant(res.cInfoList);
      localStorage.setItem('tenants', JSON.stringify(res.cInfoList));
    }
  }
});

export default useTenantStore;
