/* 存放合同相关路由 */

import { DEFAULT_LAYOUT } from '../base';
// import DEFAULT_LAYOUT from '@/layout/default-layout.vue'
import { AppRouteRecordRaw } from '../types';
import contractSettingIndexSubRoutes from './contract/baseSetting';
import contractManagementIndexSubRoutes from './contract/contractManagement';
import homeSub from './homeSub';

const CONTRACT: AppRouteRecordRaw = {
  path: '/contract',
  name: 'Contract',
  component: DEFAULT_LAYOUT,
  meta: {
    locale: '合同',
    requiresAuth: true,
    icon: 'icon-home',
    order: 6,
    hideInMenu: false,
    key: '7',
    roles: ['admin']
  },
  redirect: {
    name: 'ContractBaseSetting'
  },
  children: [
    ...homeSub,
    {
      path: 'contractBaseSetting',
      name: 'ContractBaseSetting',
      component: () =>
        import('@/views/Management/Contract/BaseSetting/index.vue'),
      meta: {
        locale: '基础设置',
        requiresAuth: true,
        icon: 'icon-jichushezhi',
        roles: ['*'],
        hideInMenu: false
      }
    },
    ...contractSettingIndexSubRoutes,
    // {
    //   path: 'ContractStatistics',
    //   name: 'ContractStatistics',
    //   component: () =>
    //     import('@/views/Management/Contract/ContractStatistics/index.vue'),
    //   meta: {
    //     locale: '合同统计',
    //     requiresAuth: true,
    //     icon: 'icon-jichushezhi',
    //     roles: ['*'],
    //     hideInMenu: false
    //   }
    // },
    {
      path: 'contractList',
      name: 'ContractList',
      meta: {
        locale: '合同列表',
        requiresAuth: true,
        icon: 'icon-jichushezhi',
        roles: ['*'],
        hideInMenu: false
      },
      children: [
        {
          path: 'contractSigning',
          name: 'ContractSigning',
          component: () =>
            import(
              '@/views/Management/Contract/ContractList/Signing/index.vue'
            ),
          meta: {
            locale: '合同签订',
            requiresAuth: true,
            roles: ['*'],
            hideInMenu: false
          }
        },
        {
          path: 'contractChangeList',
          name: 'ContractChangeList',
          component: () =>
            import('@/views/Management/Contract/ContractList/Change/index.vue'),
          meta: {
            locale: '合同变更',
            requiresAuth: true,
            roles: ['*'],
            hideInMenu: false
          }
        },
        {
          path: 'contractRenewal',
          name: 'ContractRenewal',
          component: () =>
            import(
              '@/views/Management/Contract/ContractList/Renewal/index.vue'
            ),
          meta: {
            locale: '合同续签',
            requiresAuth: true,
            roles: ['*'],
            hideInMenu: false
          }
        },
        {
          path: 'contractRelieve',
          name: 'ContractRelieve',
          component: () =>
            import(
              '@/views/Management/Contract/ContractList/Relieve/index.vue'
            ),
          meta: {
            locale: '合同解除',
            requiresAuth: true,
            roles: ['*'],
            hideInMenu: false
          }
        },
        {
          path: 'contractTermination',
          name: 'ContractTermination',
          component: () =>
            import(
              '@/views/Management/Contract/ContractList/Termination/index.vue'
            ),
          meta: {
            locale: '合同终止',
            requiresAuth: true,
            roles: ['*'],
            hideInMenu: false
          }
        }
        // {
        //   path: 'contractVoidSigned',
        //   name: 'ContractVoidSigned',
        //   component: () =>
        //     import(
        //       '@/views/Management/Contract/ContractCenter/VoidSigned/index.vue'
        //     ),
        //   meta: {
        //     locale: '已作废合同',
        //     requiresAuth: true,
        //     icon: 'icon-jichushezhi',
        //     roles: ['*'],
        //     hideInMenu: false
        //   }
        // },

        // ...contractManagementIndexSubRoutes
      ]
    },
    {
      path: 'contractCenter',
      name: 'ContractCenter',
      meta: {
        locale: '合同签署',
        requiresAuth: true,
        icon: 'icon-jichushezhi',
        roles: ['*'],
        hideInMenu: false
      },
      children: [
        {
          path: 'contractWaitSigned',
          name: 'ContractWaitSigned',
          component: () =>
            import(
              '@/views/Management/Contract/ContractCenter/WaitSigned/index.vue'
            ),
          meta: {
            locale: '待签署',
            requiresAuth: true,
            roles: ['*'],
            hideInMenu: false
          }
        },
        {
          path: 'contractUnderSigned',
          name: 'ContractUnderSigned',
          component: () =>
            import(
              '@/views/Management/Contract/ContractCenter/UnderSigned/index.vue'
            ),
          meta: {
            locale: '签署中',
            requiresAuth: true,
            roles: ['*'],
            hideInMenu: false
          }
        },
        {
          path: 'contractHaveSigned',
          name: 'ContractHaveSigned',
          component: () =>
            import(
              '@/views/Management/Contract/ContractCenter/HaveSigned/index.vue'
            ),
          meta: {
            locale: '已签署',
            requiresAuth: true,
            roles: ['*'],
            hideInMenu: false
          }
        },
        // {
        //   path: 'contractVoidSigned',
        //   name: 'ContractVoidSigned',
        //   component: () =>
        //     import(
        //       '@/views/Management/Contract/ContractCenter/VoidSigned/index.vue'
        //     ),
        //   meta: {
        //     locale: '已作废合同',
        //     requiresAuth: true,
        //     icon: 'icon-jichushezhi',
        //     roles: ['*'],
        //     hideInMenu: false
        //   }
        // },

        ...contractManagementIndexSubRoutes
      ]
    },
    {
      path: 'contractFiles',
      name: 'ContractFiles',
      component: () =>
        import('@/views/Management/Contract/ContractFiles/index.vue'),
      meta: {
        locale: '合同档案',
        requiresAuth: true,
        icon: 'icon-jichushezhi',
        roles: ['*'],
        hideInMenu: false
      }
    },
  ]
};

export default CONTRACT;
