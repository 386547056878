/* 存放消息中心路由 */
import { DEFAULT_LAYOUT } from '../base';
// import DEFAULT_LAYOUT from '@/layout/default-layout.vue'
import { AppRouteRecordRaw } from '../types';

const C_MESSAGECENTER: AppRouteRecordRaw = {
  path: 'messageCenter',
  name: 'C_MessageCenter',
  component: () => import('@/views/Client/MessageCenter/index.vue'),
  meta: {
    locale: '消息中心',
    requiresAuth: true,
    icon: 'icon-home',
    order: 1,
    hideInMenu: false,
    roles: ['*']
  }
};

export default C_MESSAGECENTER;
