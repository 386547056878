/*
 * @Author: 陈俊升 761723995@qq.com
 * @Date: 2024-02-23 09:14:29
 * @LastEditors: 陈俊升 761723995@qq.com
 * @LastEditTime: 2024-03-26 10:38:01
 * @FilePath: /hr_new_vue3/src/router/routes/management/administration/index.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/* 储存行政模块里面的相关子路由（不显示在侧边导航栏的路由） */

const administrationSubRoutes: any = [
  {
    path: 'announcementCategory',
    name: 'AnnouncementCategory',
    component: () =>
      import(
        '@/views/Management/Administration/AnnouncementCategory/index.vue'
      ),
    meta: {
      locale: '企业公告类别',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'enterpriseAnnouncementAdd',
    name: 'EnterpriseAnnouncementAdd',
    component: () =>
      import(
        '@/views/Management/Administration/EnterpriseAnnouncement/AddAnnouncement/index.vue'
      ),
    meta: {
      locale: '新增公告',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  // 公告详情改为弹窗 不再使用
  // {
  //   path: 'enterpriseAnnouncementDetail/:id',
  //   name: 'EnterpriseAnnouncementDetail',
  //   component: () =>
  //     import(
  //       '@/views/Management/Administration/EnterpriseAnnouncement/Detail/index.vue'
  //     ),
  //   meta: {
  //     locale: '公告详情',
  //     requiresAuth: true,
  //     roles: ['*'],
  //     hideInMenu: true
  //   }
  // },
  {
    path: 'enterpriseAnnouncementRead/:id',
    name: 'EnterpriseAnnouncementRead',
    component: () =>
      import(
        '@/views/Management/Administration/EnterpriseAnnouncement/Read/index.vue'
      ),
    meta: {
      locale: '公告查阅情况',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'systemCategory',
    name: 'SystemCategory',
    component: () =>
      import(
        '@/views/Management/Administration/SystemCategory/index.vue'
      ),
    meta: {
      locale: '新增制度类别',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'enterpriseSystemAdd',
    name: 'EnterpriseSystemAdd',
    component: () =>
      import(
        '@/views/Management/Administration/EnterpriseSystem/AddAnnouncement/index.vue'
      ),
    meta: {
      locale: '新增制度',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'enterpriseSystemRead/:id',
    name: 'EnterpriseSystemtRead',
    component: () =>
      import(
        '@/views/Management/Administration/EnterpriseSystem/Read/index.vue'
      ),
    meta: {
      locale: '制度查阅情况',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  // 改为弹窗 不用
  // {
  //   path: 'enterpriseSystemDetail/:id',
  //   name: 'EnterpriseSystemDetail',
  //   component: () =>
  //     import(
  //       '@/views/Management/Administration/EnterpriseSystem/Detail/index.vue'
  //     ),
  //   meta: {
  //     locale: '查看制度详情',
  //     requiresAuth: true,
  //     roles: ['*'],
  //     hideInMenu: true
  //   }
  // },

  {
    path: 'activityManagementAdd',
    name: 'ActivityManagementAdd',
    component: () =>
      import(
        '@/views/Management/Administration/ActivityManagement/AddPic/index.vue'
      ),
    meta: {
      locale: '新增活动管理图片',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'activityManagementReview/:id',
    name: 'ActivityManagementReview',
    component: () =>
      import(
        '@/views/Management/Administration/ActivityManagement/Detail/index.vue'
      ),
    meta: {
      locale: '查看活动管理详情',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  }
];

export default administrationSubRoutes;
