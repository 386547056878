import { defineStore } from 'pinia';
import { useRouter } from 'vue-router';
import { Notification } from '@arco-design/web-vue';
import type { NotificationReturn } from '@arco-design/web-vue/es/notification/interface';
import type { RouteRecordNormalized } from 'vue-router';
import HOME from '@/router/routes/management/home';
import ORGANIZATION from '@/router/routes/management/organization';
import HUMAN from '@/router/routes/management/human';
import PERFORMANCE from '@/router/routes/management/performance';
import CONTRACT from '@/router/routes/management/contract';
import SALARY from '@/router/routes/management/salary';
import ATTENDANCE from '@/router/routes/management/attendance';
import ADMINISTRATION from '@/router/routes/management/administration';
import Approval from '@/router/routes/management/Approval';
import Setting from '@/router/routes/management/setting';

import WorkPlace from '@/router/routes/management/workPlace';

const router = useRouter();
const useMenusStore = defineStore('menus', {
  state: () => {
    return {
      // 存放当前菜单路由,默认为首页
      currMenus: [HOME],
      currentKey: ''
    };
  },

  actions: {
    // 点击头部导航获取当前的侧边栏菜单
    setCurrMenus(item: string) {
      switch (item) {
        case 'home': // 首页
          this.currMenus = []; // 每次跳转先清空
          this.currMenus.push(HOME);
          sessionStorage.setItem('currMenus', JSON.stringify(HOME)); // 将当前路由信息保存到sessionStorage
          break;
        case 'organization': // 组织
          this.currMenus = [];
          this.currMenus.push(ORGANIZATION);
          sessionStorage.setItem('currMenus', JSON.stringify(ORGANIZATION));
          break;
        case 'human': // 人事
          this.currMenus = [];
          this.currMenus.push(HUMAN);
          sessionStorage.setItem('currMenus', JSON.stringify(HUMAN));
          break;
        case 'attendance': // 考勤
          this.currMenus = [];
          this.currMenus.push(ATTENDANCE);
          sessionStorage.setItem('currMenus', JSON.stringify(ATTENDANCE));
          break;
        case 'performance': // 绩效
          this.currMenus = [];
          this.currMenus.push(PERFORMANCE);
          sessionStorage.setItem('currMenus', JSON.stringify(PERFORMANCE));
          break;
        case 'salary': // 薪酬
          this.currMenus = [];
          this.currMenus.push(SALARY);
          sessionStorage.setItem('currMenus', JSON.stringify(SALARY));
          break;
        case 'contract': // 合同
          this.currMenus = [];
          this.currMenus.push(CONTRACT);
          sessionStorage.setItem('currMenus', JSON.stringify(CONTRACT));
          break;
        case 'administration': // 行政
          this.currMenus = [];
          this.currMenus.push(ADMINISTRATION);
          sessionStorage.setItem('currMenus', JSON.stringify(ADMINISTRATION));
          break;
        case 'approval': // 审批管理
          this.currMenus = [];
          this.currMenus.push(Approval);
          sessionStorage.setItem('currMenus', JSON.stringify(Approval));
          break;
        case 'WorkPlace': // 工作台特殊处理
          this.currMenus = [];
          this.currMenus.push(WorkPlace);
          sessionStorage.setItem('currMenus', JSON.stringify(WorkPlace));
          break;
        case 'setting': // 工作台特殊处理
          this.currMenus = [];
          this.currMenus.push(Setting);
          sessionStorage.setItem('currMenus', JSON.stringify(Setting));
          break;

        default:
          this.currMenus = [];
          sessionStorage.setItem('currMenus', JSON.stringify([]));
          break;
      }
    },
    setMenuKey(key: string) {
      this.currentKey = key;
      sessionStorage.setItem('currentMenuKey', key);
    },
    getCurrentMenuKey() {
      return sessionStorage.getItem('currentMenuKey');
    }
  }
});

export default useMenusStore;
