/* 存放绩效相关路由 */

import { DEFAULT_LAYOUT } from '../base';
// import DEFAULT_LAYOUT from '@/layout/default-layout.vue'
import { AppRouteRecordRaw } from '../types';
import performanceBaseSettingIndexSubRoutes from './performance/baseSetting';
import homeSub from './homeSub';

const PERFORMANCE: AppRouteRecordRaw = {
  path: '/performance',
  name: 'Performance',
  component: DEFAULT_LAYOUT,
  meta: {
    locale: '绩效',
    requiresAuth: true,
    icon: 'icon-home',
    order: 4,
    hideInMenu: false,
    key: '5'
  },
  redirect: {
    name: 'PerformanceBaseSetting'
  },
  children: [
    ...homeSub,
    {
      path: 'performanceBaseSetting',
      name: 'PerformanceBaseSetting',
      component: () =>
        import('@/views/Management/Performance/BaseSetting/index.vue'),
      meta: {
        locale: '基础设置',
        requiresAuth: true,
        icon: 'icon-jichushezhi',
        roles: ['*'],
        hideInMenu: false
      }
    },
    ...performanceBaseSettingIndexSubRoutes,
    {
      path: 'performanceManagement',
      name: 'PerformanceManagement',
      meta: {
        locale: '考核管理',
        requiresAuth: true,
        icon: 'icon-writing',
        roles: ['*'],
        hideInMenu: false
      },
      children: [
        {
          path: 'performanceSub',
          name: 'PerformanceSub',
          component: () =>
            import(
              '@/views/Management/Performance/PerformanceManagement/Performance/index.vue'
            ),
          meta: {
            locale: '绩效考核',
            requiresAuth: true,
            // icon: 'icon-jichushezhi',
            roles: ['*'],
            hideInMenu: false
          }
        },
        {
          path: 'probation',
          name: 'Probation',
          component: () =>
            import(
              '@/views/Management/Performance/PerformanceManagement/Probation/index.vue'
            ),
          meta: {
            locale: '试用期考核',
            requiresAuth: true,
            // icon: 'icon-jichushezhi',
            roles: ['*'],
            hideInMenu: false
          }
        },
        {
          path: 'probation/exam',
          name: 'ProbationExam',
          component: () =>
            import(
              '@/views/Management/Performance/PerformanceManagement/ProbationExam/index.vue'
            ),
          meta: {
            locale: '试用期考核列表',
            requiresAuth: true,
            // icon: 'icon-jichushezhi',
            roles: ['*'],
            hideInMenu: true
          }
        },
        {
          path: 'promotion',
          name: 'Promotion',
          component: () =>
            import(
              '@/views/Management/Performance/PerformanceManagement/Promotion/index.vue'
            ),
          meta: {
            locale: '晋升考核',
            requiresAuth: true,
            // icon: 'icon-jichushezhi',
            roles: ['*'],
            hideInMenu: false
          }
        },
        {
          path: 'tenure',
          name: 'Tenure',
          component: () =>
            import(
              '@/views/Management/Performance/PerformanceManagement/Tenure/index.vue'
            ),
          meta: {
            locale: '任期考核',
            requiresAuth: true,
            // icon: 'icon-jichushezhi',
            roles: ['*'],
            hideInMenu: false
          }
        },
        {
          path: 'performanceContract',
          name: 'PerformanceContract',
          component: () =>
            import(
              '@/views/Management/Performance/PerformanceManagement/Contract/index.vue'
            ),
          meta: {
            locale: '合同续签考核',
            requiresAuth: true,
            // icon: 'icon-jichushezhi',
            roles: ['*'],
            hideInMenu: false
          }
        },
        {
          path: 'other',
          name: 'Other',
          component: () =>
            import(
              '@/views/Management/Performance/PerformanceManagement/Other/index.vue'
            ),
          meta: {
            locale: '其他考核',
            requiresAuth: true,
            // icon: 'icon-jichushezhi',
            roles: ['*'],
            hideInMenu: false
          }
        },
        {
          path: 'appeal',
          name: 'Appeal',
          component: () =>
            import(
              '@/views/Management/Performance/PerformanceManagement/AppealHandling/index.vue'
            ),
          meta: {
            locale: '申诉受理',
            requiresAuth: true,
            // icon: 'icon-jichushezhi',
            roles: ['*'],
            hideInMenu: false
          }
        },
        {
          path: 'changeHandling',
          name: 'ChangeHandling',
          component: () =>
            import(
              '@/views/Management/Performance/PerformanceManagement/ChangeHandling/index.vue'
            ),
          meta: {
            locale: '变更受理',
            requiresAuth: true,
            // icon: 'icon-jichushezhi',
            roles: ['*'],
            hideInMenu: false
          }
        }
      ]
    }
  ]
};

export default PERFORMANCE;
