/* 储存考勤模块-基础信息里面的相关子路由（不显示在侧边导航栏的路由） */

const attendanceBaseSettingIndexSubRoutes: any = [
  {
    path: 'attendanceMainSetting',
    name: 'AttendanceMainSetting',
    component: () =>
      import(
        '@/views/Management/Attendance/BaseSetting/AttendanceSetting/MainSetting/index.vue'
      ),
    meta: {
      locale: '考勤项设置',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'attendanceRemind',
    name: 'AttendanceRemind',
    component: () =>
      import(
        '@/views/Management/Attendance/BaseSetting/AttendanceSetting/Remind/index.vue'
      ),
    meta: {
      locale: '考勤提醒',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'attendanceCycle',
    name: 'AttendanceCycle',
    component: () =>
      import(
        '@/views/Management/Attendance/BaseSetting/AttendanceSetting/AttendanceCycle/index.vue'
      ),
    meta: {
      locale: '考勤周期',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'addAttendanceCycle',
    name: 'AddAttendanceCycle',
    component: () =>
      import(
        '@/views/Management/Attendance/BaseSetting/AttendanceSetting/AttendanceCycle/AddAttendanceCycle/index.vue'
      ),
    meta: {
      locale: '考勤周期设置',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'attendanceOtherSetting',
    name: 'AttendanceOtherSetting',
    component: () =>
      import(
        '@/views/Management/Attendance/BaseSetting/AttendanceSetting/OtherSetting/index.vue'
      ),
    meta: {
      locale: '其他设置',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'attendanceRecord',
    name: 'AttendanceRecord',
    component: () =>
      import(
        '@/views/Management/Attendance/BaseSetting/AttendanceSetting/Record/index.vue'
      ),
    meta: {
      locale: '操作记录',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'workingCalendar',
    name: 'WorkingCalendar',
    component: () =>
      import(
        '@/views/Management/Attendance/BaseSetting/AttendanceSetting/WorkingCalendar/index.vue'
      ),
    meta: {
      locale: '工作日历',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'editWorkingCalendar',
    name: 'EditWorkingCalendar',
    component: () =>
      import(
        '@/views/Management/Attendance/BaseSetting/AttendanceSetting/WorkingCalendar/EditWorkingCalender/index.vue'
      ),
    meta: {
      locale: '编辑工作日历',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'checkInMethod',
    name: 'CheckInMethod',
    component: () =>
      import(
        '@/views/Management/Attendance/BaseSetting/AttendanceSetting/CheckInMethod/index.vue'
      ),
    meta: {
      locale: '打卡方式',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'clockInPlan',
    name: ' ClockInPlan',
    component: () =>
      import(
        '@/views/Management/Attendance/BaseSetting/AttendanceSetting/CheckInMethod/ClockInPlan/index.vue'
      ),
    meta: {
      locale: '打卡方案设置',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },

  {
    path: 'attendanceMachineManagement',
    name: 'AttendanceMachineManagement',
    component: () =>
      import(
        '@/views/Management/Attendance/BaseSetting/AttendanceSetting/CheckInMethod/AttendanceMachineManagement/index.vue'
      ),
    meta: {
      locale: '考勤机管理',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'editAttendanceMachine',
    name: 'EditAttendanceMachine',
    component: () =>
      import(
        '@/views/Management/Attendance/BaseSetting/AttendanceSetting/CheckInMethod/AttendanceMachineManagement/EditAttendanceMachine/index.vue'
      ),
    meta: {
      locale: '考勤机设置',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },

  {
    path: 'attendanceHolidayType',
    name: 'AttendanceHolidayType',
    component: () =>
      import(
        '@/views/Management/Attendance/BaseSetting/HolidaySetting/HolidayType/index.vue'
      ),
    meta: {
      locale: '假期类型',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'attendanceHolidayRemind',
    name: 'AttendanceHolidayRemind',
    component: () =>
      import(
        '@/views/Management/Attendance/BaseSetting/HolidaySetting/HolidayRemind/index.vue'
      ),
    meta: {
      locale: '假期提醒设置',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'attendanceHolidayRecord',
    name: 'AttendanceHolidayRecord',
    component: () =>
      import(
        '@/views/Management/Attendance/BaseSetting/HolidaySetting/HolidayRecord/index.vue'
      ),
    meta: {
      locale: '假期操作记录',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  }
];

export default attendanceBaseSettingIndexSubRoutes;
