/* 存放工作台、消息、通讯录、文档四个路由，用于非首页模块固定上面4个子路由 */

import { DEFAULT_LAYOUT } from '../base';
// import DEFAULT_LAYOUT from '@/layout/default-layout.vue'
import { AppRouteRecordRaw } from '../types';

const HOMESUB: any = [
  {
    path: '/workplace',
    name: 'WorkPlace',
    component: () => import('@/views/Management/Home/WorkPlace/index.vue'),
    meta: {
      locale: '工作台',
      requiresAuth: true,
      icon: 'icon-home',
      roles: ['admin'],
      hideInMenu: false
    }
  },
  {
    path: '/userSetting',
    name: 'UserSetting',
    component: () => import('@/views/Management/Home/UserSetting/index.vue'),
    meta: {
      locale: '个人设置',
      requiresAuth: true,
      icon: 'icon-home',
      roles: ['admin'],
      hideInMenu: true
    }
  },
  {
    path: '/message',
    name: 'Message',
    component: () => import('@/views/Management/Home/Message/index.vue'),
    meta: {
      locale: '沟通',
      requiresAuth: true,
      icon: 'icon-message-comments',
      roles: ['admin'],
      hideInMenu: false
    }
  },
  {
    path: '/addressBook',
    name: 'AddressBook',
    component: () => import('@/views/Management/Home/AddressBook/index.vue'),
    meta: {
      locale: '通讯录',
      requiresAuth: true,
      icon: 'icon-calendar',
      roles: ['admin'],
      hideInMenu: false
    }
  },
  {
    path: '/Storage',
    name: 'Storage',
    component: () => import('@/views/Management/Home/Storage/index.vue'),
    meta: {
      locale: '储存空间',
      requiresAuth: true,
      icon: 'icon-folder',
      roles: ['admin'],
      hideInMenu: false
    }
  },
  {
    path: '/storageManagement',
    name: 'StorageManagement',
    component: () =>
      import('@/views/Management/Home/Storage/StorageManagement/index.vue'),
    meta: {
      locale: '储存管理',
      requiresAuth: true,
      icon: 'icon-folder',
      roles: ['admin'],
      hideInMenu: true
    }
  },
  {
    path: '/document',
    name: 'Document',
    component: () => import('@/views/Management/Home/Document/index.vue'),
    meta: {
      locale: '文档',
      requiresAuth: true,
      icon: 'icon-folder',
      roles: ['admin'],
      hideInMenu: false,
      // 下划线
      underline: true
    }
  },
  {
    path: '/fileTabsPage',
    name: 'FileTabsPage',
    component: () => import('@/views/Management/Home/Document/OldTabs.vue'),
    meta: {
      locale: '文件新标签页',
      requiresAuth: true,
      icon: 'icon-folder',
      roles: ['admin'],
      hideInMenu: true
    }
  },
  {
    path: '/knowledgeBasePage',
    name: 'KnowledgeBasePage',
    component: () =>
      import(
        '@/views/Management/Home/Document/KnowledgeBase/KnowledgeBasePage/index.vue'
      ),
    meta: {
      locale: '知识库详情页',
      requiresAuth: true,
      icon: 'icon-folder',
      roles: ['admin'],
      hideInMenu: true
    }
  },
  // {
  //   path: '/authManagement',
  //   name: 'AuthManagement',
  //   component: () => import('@/views/Management/Home/AuthManagement/index.vue'),
  //   meta: {
  //     locale: '权限管理',
  //     requiresAuth: true,
  //     icon: 'icon-folder',
  //     roles: ['admin'],
  //     hideInMenu: false
  //   }
  // },
  // {
  //   path: '/roleManagement',
  //   name: 'RoleManagement',
  //   component: () => import('@/views/Management/Home/RoleManagement/index.vue'),
  //   meta: {
  //     locale: '角色管理',
  //     requiresAuth: true,
  //     icon: 'icon-folder',
  //     roles: ['admin'],
  //     hideInMenu: false
  //   }
  // },
  {
    path: '/permissionGroup',
    name: 'PermissionGroup',
    component: () =>
      import(
        '@/views/Management/Home/RoleManagement/PermissionGroup/index.vue'
      ),
    meta: {
      locale: '功能权限组',
      requiresAuth: true,
      icon: 'icon-folder',
      roles: ['admin'],
      hideInMenu: true
    }
  },
  {
    path: '/handoverRecord',
    name: 'HandoverRecord',
    component: () =>
      import('@/views/Management/Home/RoleManagement/HandoverRecord/index.vue'),
    meta: {
      locale: '移交记录',
      requiresAuth: true,
      icon: 'icon-folder',
      roles: ['admin'],
      hideInMenu: true
    }
  },
  {
    path: '/handoverRecord/settings',
    name: 'HandoverRecordSettings',
    component: () =>
      import(
        '@/views/Management/Home/RoleManagement/HandoverRecord/PermissionSettings/index.vue'
      ),
    meta: {
      locale: '移交记录配置',
      requiresAuth: true,
      icon: 'icon-folder',
      roles: ['admin'],
      hideInMenu: true
    }
  },
  {
    path: '/permissionGroup/permissionSettings',
    name: 'PermissionGroupSettings',
    component: () =>
      import(
        '@/views/Management/Home/RoleManagement/PermissionGroup/PermissionSettings/index.vue'
      ),
    meta: {
      locale: '功能权限组设置',
      requiresAuth: true,
      icon: 'icon-folder',
      roles: ['admin'],
      hideInMenu: true
    }
  },
  {
    path: '/permissionSettings',
    name: 'PermissionSettings',
    component: () =>
      import(
        '@/views/Management/Home/RoleManagement/PermissionSettings/index.vue'
      ),
    meta: {
      locale: '功能权限设置',
      requiresAuth: true,
      icon: 'icon-folder',
      roles: ['admin'],
      hideInMenu: true
    }
  },

  {
    path: '/InformationCenter',
    name: 'InformationCenter',
    component: () =>
      import('@/views/Management/Home/InformationCenter/index.vue'),
    meta: {
      locale: '消息中心',
      requiresAuth: true,
      icon: 'icon-folder',
      roles: ['admin'],
      hideInMenu: true
    }
  },
  {
    path: '/InformationCenterDetails',
    name: 'InformationCenterDetails',
    component: () =>
      import(
        '@/views/Management/Home/InformationCenter/InformationCenterDetails/index.vue'
      ),
    meta: {
      locale: '信息详情',
      requiresAuth: true,
      icon: 'icon-folder',
      roles: ['admin'],
      hideInMenu: true
    }
  },
  {
    path: '/InformationCenterMessages',
    name: 'InformationCenterMessages',
    component: () =>
      import(
        '@/views/Management/Home/InformationCenter/InformationCenterMessages/index.vue'
      ),
    meta: {
      locale: '消息详情',
      requiresAuth: true,
      icon: 'icon-folder',
      roles: ['admin'],
      hideInMenu: true
    }
  },
  {
    path: '/objPermission',
    name: 'ObjPermission',
    component: () =>
      import('@/views/Management/Home/RoleManagement/ObjPermission.vue'),
    meta: {
      locale: '配置对象角色权限',
      requiresAuth: true,
      icon: 'icon-folder',
      roles: ['admin'],
      hideInMenu: true
    }
  },
  // {
  //   path: '/authAgent',
  //   name: 'AuthAgent',
  //   component: () => import('@/views/Management/Home/AuthAgent/index.vue'),
  //   meta: {
  //     locale: '代理权限',
  //     requiresAuth: true,
  //     icon: 'icon-folder',
  //     roles: ['admin'],
  //     hideInMenu: false
  //   }
  // },
  {
    path: '/myCalendar',
    name: 'MyCalendar',
    component: () =>
      import('@/views/Management/Home/WorkPlace/MyCalendar/index.vue'),
    meta: {
      locale: '我的日历',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  }
];

export default HOMESUB;
