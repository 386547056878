import { defineStore } from 'pinia';
import { Notification } from '@arco-design/web-vue';
import type { NotificationReturn } from '@arco-design/web-vue/es/notification/interface';
import type { RouteRecordNormalized } from 'vue-router';
import MESSAGECENTER from '@/router/routes/client/messageCenter';

import { useRouter } from 'vue-router';

const router = useRouter();
const useClientMenusStore = defineStore('menus', {
  state: () => {
    return {
      // 存放当前菜单路由,默认为首页
      currMenus: [MESSAGECENTER]
    };
  },

  actions: {
    // 点击头部导航获取当前的侧边栏菜单
    setCurrMenus(item: string) {
      switch (item) {
        case 'home': // 首页
          this.currMenus = []; // 每次跳转先清空
          this.currMenus.push(MESSAGECENTER);
          sessionStorage.setItem('currMenus', JSON.stringify(MESSAGECENTER)); // 将当前路由信息保存到sessionStorage
          break;
        // case 'organization': // 订单管理
        //   this.currMenus = [];
        //   this.currMenus.push(ORGANIZATION);
        //   sessionStorage.setItem('currMenus', JSON.stringify(ORGANIZATION));
        //   break;
        // case 'human': // 采购管理
        //   this.currMenus = [];
        //   this.currMenus.push(HUMAN);
        //   sessionStorage.setItem('currMenus', JSON.stringify(HUMAN));
        //   break;
        // case 'contract': // 合同管理
        //   this.currMenus = [];
        //   this.currMenus.push(CONTRACT);
        //   sessionStorage.setItem('currMenus', JSON.stringify(CONTRACT));
        //   break;
        // case 'inventory': // 库存管理
        //   this.currMenus = [];
        //   this.currMenus.push(INVENTORY);
        //   sessionStorage.setItem('currMenus', JSON.stringify(INVENTORY));
        //   break;
        //   case 'equipment': // 设备管理
        //     this.currMenus = [];
        //     this.currMenus.push(EQUIPMENT);
        //     sessionStorage.setItem('currMenus', JSON.stringify(EQUIPMENT));
        //     break;
        default:
          break;
      }
    }
  }
});

export default useClientMenusStore;
