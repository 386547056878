/* 储存考勤模块-考勤管理里面的相关子路由（不显示在侧边导航栏的路由） */

const attendanceManagementSubRoutes: any = [
  {
    path: 'attendanceManagementGroupAdd',
    name: 'AttendanceManagementGroupAdd',
    component: () =>
      import(
        '@/views/Management/Attendance/AttendanceManagement/AttendanceGroup/AddGroup/index.vue'
      ),
    meta: {
      locale: '新增豁免人员',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'addBaseSetting',
    name: 'AddBaseSetting',
    component: () =>
      import(
        '@/views/Management/Attendance/AttendanceManagement/AttendanceGroup/AddBaseSetting/index.vue'
      ),
    meta: {
      locale: '新增/编辑考勤组',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'attendanceManagementClassSettingAdd',
    name: 'AttendanceManagementClassSettingAdd',
    component: () =>
      import(
        '@/views/Management/Attendance/AttendanceManagement/ClassesManagement/AddClassSetting.vue'
      ),
    meta: {
      locale: '新增/编辑班次设置',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'addOutsideRules',
    name: 'AddOutsideRules',
    component: () =>
      import(
        '@/views/Management/Attendance/AttendanceManagement/AttendanceRules/OutsideRules/AddRules/index.vue'
      ),
    meta: {
      locale: '新增/修改外勤规则',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'shiftSchedule',
    name: 'ShiftSchedule',
    component: () =>
      import(
        '@/views/Management/Attendance/AttendanceManagement/ScheduleManagement/ShiftSchedule/index.vue'
      ),
    meta: {
      locale: '排班时间',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'addShiftSchedule',
    name: 'AddShiftSchedule',
    component: () =>
      import(
        '@/views/Management/Attendance/AttendanceManagement/ScheduleManagement/AddShiftSchedule/index.vue'
      ),
    meta: {
      locale: '新增排班时间',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'replenishmentRules',
    name: 'ReplenishmentRules',
    component: () =>
      import(
        '@/views/Management/Attendance/AttendanceManagement/AttendanceRules/CardRules/ReplenishmentRules/index.vue'
      ),
    meta: {
      locale: '新增/修改补卡规则',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'overtimeRules',
    name: 'OvertimeRules',
    component: () =>
      import(
        '@/views/Management/Attendance/AttendanceManagement/AttendanceRules/OtRules/OvertimeRules/index.vue'
      ),
    meta: {
      locale: '新增/修改加班规则',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'cardCancellationRules',
    name: 'CardCancellationRules',
    component: () =>
      import(
        '@/views/Management/Attendance/AttendanceManagement/AttendanceRules/AllowanceRules/CardCancellationRules/index.vue'
      ),
    meta: {
      locale: '新增/修改销卡规则',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'attendanceDetails',
    name: 'AttendanceDetails',
    component: () =>
      import(
        '@/views/Management/Attendance/AttendanceStatistics/AttendanceMonthly/AttendanceDetails/index.vue'
      ),
    meta: {
      locale: '考勤详情',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'holidayRecord',
    name: 'HolidayRecord',
    component: () =>
      import(
        '@/views/Management/Attendance/HolidayManagement/HolidayBalance/HolidayRecord/index.vue'
      ),
    meta: {
      locale: '假期使用记录',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'addLeaveRules',
    name: 'AddLeaveRules',
    // component: () =>
    //   import(
    //     '@/views/Management/Attendance/HolidayManagement/HolidayRules/AddLeaveRules/index.vue'
    //   ),
    meta: {
      locale: '新增事假规则',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'typeVacation',
    name: 'TypeVacation',
    component: () =>
      import(
        '@/views/Management/Attendance/HolidayManagement/HolidayRules/TypeVacation/index.vue'
      ),
    meta: {
      locale: '假期类型',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  // 下面的假期规则页面
  {
    path: 'annualLeaveRules',
    name: 'AnnualLeaveRules',
    component: () =>
      import(
        '@/views/Management/Attendance/HolidayManagement/AddHolidayRules/AnnualLeaveRules/index.vue'
      ),
    meta: {
      locale: '新增年假规则',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'personalLeaveRules',
    name: 'PersonalLeaveRules',
    component: () =>
      import(
        '@/views/Management/Attendance/HolidayManagement/AddHolidayRules/PersonalLeaveRules/index.vue'
      ),
    meta: {
      locale: '新增事假规则',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'vacationRules',
    name: 'VacationRules',
    component: () =>
      import(
        '@/views/Management/Attendance/HolidayManagement/AddHolidayRules/VacationRules/index.vue'
      ),
    meta: {
      locale: '新增调休假规则',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'sickLeaveRules',
    name: 'SickLeaveRules',
    component: () =>
      import(
        '@/views/Management/Attendance/HolidayManagement/AddHolidayRules/SickLeaveRules/index.vue'
      ),
    meta: {
      locale: '新增病假规则',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'workInjuryLeaveRules',
    name: 'WorkInjuryLeaveRules',
    component: () =>
      import(
        '@/views/Management/Attendance/HolidayManagement/AddHolidayRules/WorkInjuryLeaveRules/index.vue'
      ),
    meta: {
      locale: '新增工伤假规则',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'marriageLeaveRules',
    name: 'MarriageLeaveRules',
    component: () =>
      import(
        '@/views/Management/Attendance/HolidayManagement/AddHolidayRules/MarriageLeaveRules/index.vue'
      ),
    meta: {
      locale: '新增婚假规则',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'maternityLeaveRules',
    name: 'MaternityLeaveRules',
    component: () =>
      import(
        '@/views/Management/Attendance/HolidayManagement/AddHolidayRules/MaternityLeaveRules/index.vue'
      ),
    meta: {
      locale: '新增产假规则',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'maternityCheckLeaveRules',
    name: 'MaternityCheckLeaveRules',
    component: () =>
      import(
        '@/views/Management/Attendance/HolidayManagement/AddHolidayRules/MaternityCheckLeaveRules/index.vue'
      ),
    meta: {
      locale: '新增产检假规则',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'paternityLeaveRules',
    name: 'PaternityLeaveRules',
    component: () =>
      import(
        '@/views/Management/Attendance/HolidayManagement/AddHolidayRules/PaternityLeaveRules/index.vue'
      ),
    meta: {
      locale: '新增陪产假规则',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'breastfeedingLeaveRules',
    name: 'BreastfeedingLeaveRules',
    component: () =>
      import(
        '@/views/Management/Attendance/HolidayManagement/AddHolidayRules/BreastfeedingLeaveRules/index.vue'
      ),
    meta: {
      locale: '新增哺乳假规则',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'paternalLeaveRules',
    name: 'PaternalLeaveRules',
    component: () =>
      import(
        '@/views/Management/Attendance/HolidayManagement/AddHolidayRules/PaternalLeaveRules/index.vue'
      ),
    meta: {
      locale: '新增育儿假规则',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'bereavementLeaveRules',
    name: 'BereavementLeaveRules',
    component: () =>
      import(
        '@/views/Management/Attendance/HolidayManagement/AddHolidayRules/BereavementLeaveRules/index.vue'
      ),
    meta: {
      locale: '新增丧假规则',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'customLeaveRules',
    name: 'CustomLeaveRules',
    component: () =>
      import(
        '@/views/Management/Attendance/HolidayManagement/AddHolidayRules/CustomLeaveRules/index.vue'
      ),
    meta: {
      locale: '新增假规则',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  // 下面是考勤申请页面
  {
    path: 'scickLeave',
    name: 'SickLeave',
    component: () =>
      import(
        '@/views/Management/Attendance/AttendanceApproval/SickLeave/index.vue'
      ),
    meta: {
      locale: '病假申请',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'reissueaCardForm',
    name: 'ReissueaCardForm',
    component: () =>
      import(
        '@/views/Management/Attendance/AttendanceApproval/reissueaCardForm/index.vue'
      ),
    meta: {
      locale: '补卡申请',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'evectionForm',
    name: 'EvectionForm',
    component: () =>
      import(
        '@/views/Management/Attendance/AttendanceApproval/evectionForm/index.vue'
      ),
    meta: {
      locale: '出差申请',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'cancelCardForm',
    name: 'CancelCardForm',
    component: () =>
      import(
        '@/views/Management/Attendance/AttendanceApproval/cancelCardForm/index.vue'
      ),
    meta: {
      locale: '销卡申请',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'personalForm',
    name: 'PersonalForm',
    component: () =>
      import(
        '@/views/Management/Attendance/AttendanceApproval/personalForm/index.vue'
      ),
    meta: {
      locale: '事假申请',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'diseaseForm',
    name: 'DiseaseForm',
    component: () =>
      import(
        '@/views/Management/Attendance/AttendanceApproval/diseaseForm/index.vue'
      ),
    meta: {
      locale: '病假申请',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'bereavementForm',
    name: 'BereavementForm',
    component: () =>
      import(
        '@/views/Management/Attendance/AttendanceApproval/bereavementForm/index.vue'
      ),
    meta: {
      locale: '丧假申请',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'breastfeedingForm',
    name: 'BreastfeedingForm',
    component: () =>
      import(
        '@/views/Management/Attendance/AttendanceApproval/breastfeedingForm/index.vue'
      ),
    meta: {
      locale: '哺乳假申请',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'paternityForm',
    name: 'PaternityForm',
    component: () =>
      import(
        '@/views/Management/Attendance/AttendanceApproval/paternityForm/index.vue'
      ),
    meta: {
      locale: '陪产假申请',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'maternityForm',
    name: 'MaternityForm',
    component: () =>
      import(
        '@/views/Management/Attendance/AttendanceApproval/maternityForm/index.vue'
      ),
    meta: {
      locale: '产假申请',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'maternityCheckForm',
    name: 'MaternityCheckForm',
    component: () =>
      import(
        '@/views/Management/Attendance/AttendanceApproval/maternityCheckForm/index.vue'
      ),
    meta: {
      locale: '产检假申请',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'parentingForm',
    name: 'ParentingForm',
    component: () =>
      import(
        '@/views/Management/Attendance/AttendanceApproval/parentingForm/index.vue'
      ),
    meta: {
      locale: '育儿假申请',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'vacationForm',
    name: 'VacationForm',
    component: () =>
      import(
        '@/views/Management/Attendance/AttendanceApproval/vacationForm/index.vue'
      ),
    meta: {
      locale: '调休假申请',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'marriageForm',
    name: 'MarriageForm',
    component: () =>
      import(
        '@/views/Management/Attendance/AttendanceApproval/marriageForm/index.vue'
      ),
    meta: {
      locale: '婚假申请',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'annualForm',
    name: 'AnnualForm',
    component: () =>
      import(
        '@/views/Management/Attendance/AttendanceApproval/annualForm/index.vue'
      ),
    meta: {
      locale: '年假申请',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'customForm',
    name: 'CustomForm',
    component: () =>
      import(
        '@/views/Management/Attendance/AttendanceApproval/customForm/index.vue'
      ),
    meta: {
      locale: '假期申请',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'workInjuryForm',
    name: 'WorkInjuryForm',
    component: () =>
      import(
        '@/views/Management/Attendance/AttendanceApproval/workInjuryForm/index.vue'
      ),
    meta: {
      locale: '工伤假申请',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  // 审核员查看页面
  {
    path: 'reissueaCardAudit',
    name: 'ReissueaCardAudit',
    component: () =>
      import(
        '@/views/Management/Attendance/AttendanceAudit/reissueaCardAudit/index.vue'
      ),
    meta: {
      locale: '补卡审核',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'evectionAudit',
    name: 'EvectionAudit',
    component: () =>
      import(
        '@/views/Management/Attendance/AttendanceAudit/evectionAudit/index.vue'
      ),
    meta: {
      locale: '出差审核',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'overtimeAudit',
    name: 'OvertimeAudit',
    component: () =>
      import(
        '@/views/Management/Attendance/AttendanceAudit/overtimeAudit/index.vue'
      ),
    meta: {
      locale: '加班审核',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
];

export default attendanceManagementSubRoutes;
