/* 存放首页相关路由 */

import { DEFAULT_LAYOUT } from '../base';
// import DEFAULT_LAYOUT from '@/layout/default-layout.vue'
import { AppRouteRecordRaw } from '../types';
import homeSub from './homeSub';

const HOME: AppRouteRecordRaw = {
  path: '/home',
  name: 'home',
  component: DEFAULT_LAYOUT,
  meta: {
    locale: '首页',
    requiresAuth: true,
    icon: 'icon-home',
    order: 0,
    hideInMenu: false,
    key: '1',
    roles: ['admin']
  },
  redirect: {
    name: 'WorkPlace'
  },
  children: [
    ...homeSub,
    {
      path: '/waitDone',
      name: 'WaitDone',
      component: () => import('@/views/Management/Home/WaitDone/index.vue'),
      meta: {
        locale: '我的待办',
        requiresAuth: true,
        icon: 'icon-jichushezhi',
        roles: ['admin'],
        hideInMenu: false
      }
    },
    {
      path: '/myApproval',
      name: 'MyApproval',
      component: () => import('@/views/Management/Home/MyApproval/index.vue'),
      meta: {
        locale: '我的审批',
        requiresAuth: true,
        icon: 'icon-jichushezhi',
        roles: ['*'],
        hideInMenu: false
      }
    },
    {
      path: '/myApproval/approvalDetails',
      name: 'ApprovalDetails',
      component: () =>
        import('@/views/Management/Home/MyApproval/ApprovalDetails/index.vue'),
      meta: {
        locale: '审批详情',
        requiresAuth: true,
        icon: 'icon-jichushezhi',
        roles: ['*'],
        hideInMenu: true
      }
    },
    {
      path: '/approval/center',
      name: 'approvalCenter',
      component: () => import('@/views/Management/Approval/Center/index.vue'),
      meta: {
        locale: '申请中心',
        requiresAuth: true,
        icon: 'icon-jichushezhi',
        roles: ['*'],
        hideInMenu: false
      }
    },
    {
      path: '/deptManagement',
      name: 'DeptManagement',
      meta: {
        locale: '部门管理',
        requiresAuth: true,
        icon: 'icon-jichushezhi',
        roles: ['*'],
        hideInMenu: false
      },
      redirect: {
        name: 'DeptPeopleList'
      },
      children: [
        {
          path: 'deptPeopleList',
          name: 'DeptPeopleList',
          component: () =>
            import(
              '@/views/Management/Home/DeptManagement/DeptPeopleList/index.vue'
            ),
          meta: {
            locale: '部门花名册',
            requiresAuth: true,
            icon: 'icon-home',
            hideInMenu: false,
            roles: ['*']
          }
        },
        {
          path: 'deptApply',
          name: 'DeptApply',
          component: () =>
            import(
              '@/views/Management/Home/DeptManagement/DeptApply/index.vue'
            ),
          meta: {
            locale: '部门申请',
            requiresAuth: true,
            icon: 'icon-home',
            hideInMenu: false,
            roles: ['*']
          }
        },
        {
          path: 'deptAttendance',
          name: 'DeptAttendance',
          component: () =>
            import(
              '@/views/Management/Home/DeptManagement/DeptAttendance/index.vue'
            ),
          meta: {
            locale: '部门考勤',
            requiresAuth: true,
            icon: 'icon-home',
            hideInMenu: false,
            roles: ['*']
          }
        },
        {
          path: 'deptPerformance',
          name: 'DeptPerformance',
          component: () =>
            import(
              '@/views/Management/Home/DeptManagement/DeptPerformance/index.vue'
            ),
          meta: {
            locale: '部门绩效',
            requiresAuth: true,
            icon: 'icon-home',
            hideInMenu: false,
            roles: ['*']
          }
        },
        {
          path: 'deptSalary',
          name: 'DeptSalary',
          component: () =>
            import(
              '@/views/Management/Home/DeptManagement/DeptSalary/index.vue'
            ),
          meta: {
            locale: '部门薪酬',
            requiresAuth: true,
            icon: 'icon-home',
            hideInMenu: false,
            roles: ['*']
          }
        }
      ]
    },
    {
      path: '/myPerformance',
      name: 'MyPerformance',
      meta: {
        locale: '我的绩效',
        requiresAuth: true,
        icon: 'icon-jichushezhi',
        roles: ['admin'],
        hideInMenu: false
      },
      redirect: {
        name: 'CreateAssessment'
      },
      children: [
        {
          path: 'CreateAssessment',
          name: 'CreateAssessment',
          component: () =>
            import(
              '@/views/Management/Home/MyPerformance/CreateAssessment/index.vue'
            ),
          meta: {
            locale: '创建考核方案',
            requiresAuth: true,
            roles: ['admin'],
            hideInMenu: false
          }
        },
        {
          path: 'myInternshipEva',
          name: 'MyInternshipEva',
          component: () =>
            import(
              '@/views/Management/Home/MyPerformance/InternshipEva/index.vue'
            ),
          meta: {
            locale: '实习评价',
            requiresAuth: true,
            roles: ['admin'],
            hideInMenu: false
          }
        },
        {
          path: 'myProbationAssessment',
          name: 'MyProbationAssessment',
          component: () =>
            import(
              '@/views/Management/Home/MyPerformance/ProbationAssessment/index.vue'
            ),
          meta: {
            locale: '我的绩效',
            requiresAuth: true,
            roles: ['admin'],
            hideInMenu: false
          }
        },
        {
          path: 'myProbationAssessment/detail',
          name: 'MyProbationAssessmentDetail',
          component: () =>
            import(
              '@/views/Management/Home/MyPerformance/ProbationAssessment/Detail/index.vue'
            ),
          meta: {
            locale: '试用期考核方案详情',
            requiresAuth: true,
            roles: ['admin'],
            hideInMenu: true
          }
        },
        {
          path: 'myProbationAssessment/list',
          name: 'MyProbationAssessmentList',
          component: () =>
            import(
              '@/views/Management/Home/MyPerformance/ProbationAssessment/ExamineList/index.vue'
            ),
          meta: {
            locale: '试用期考核',
            requiresAuth: true,
            roles: ['admin'],
            hideInMenu: true
          }
        },
        {
          path: 'myPerformanceAssessment',
          name: 'MyPerformanceAssessment',
          component: () =>
            import(
              '@/views/Management/Home/MyPerformance/PerformanceAssessment/index.vue'
            ),
          meta: {
            locale: '绩效考核',
            requiresAuth: true,
            roles: ['admin'],
            hideInMenu: false
          }
        },
        {
          path: 'myOtherAssessment',
          name: 'MyOtherAssessment',
          component: () =>
            import(
              '@/views/Management/Home/MyPerformance/OtherAssessment/index.vue'
            ),
          meta: {
            locale: '其他考核',
            requiresAuth: true,
            roles: ['admin'],
            hideInMenu: false
          }
        },
        {
          path: 'myPerformanceEva',
          name: 'MyPerformanceEva',
          component: () =>
            import(
              '@/views/Management/Home/MyPerformance/PerformanceEva/index.vue'
            ),
          meta: {
            locale: '绩效评价任务',
            requiresAuth: true,
            roles: ['admin'],
            hideInMenu: false
          }
        },
        {
          path: 'ExamineAppeal',
          name: 'examineAppeal',
          component: () =>
            import(
              '@/views/Management/Home/MyPerformance/ExamineAppeal/index.vue'
            ),
          meta: {
            locale: '申诉受理',
            requiresAuth: true,
            roles: ['admin'],
            hideInMenu: false
          }
        },
        {
          path: 'ChangeHandling',
          name: 'changeHandling',
          component: () =>
            import(
              '@/views/Management/Home/MyPerformance/ChangeHandling/index.vue'
            ),
          meta: {
            locale: '变更处理',
            requiresAuth: true,
            roles: ['admin'],
            hideInMenu: false
          }
        },
        {
          path: 'AssessmentResults',
          name: 'assessmentResults',
          component: () =>
            import(
              '@/views/Management/Home/MyPerformance/AssessmentResults/index.vue'
            ),
          meta: {
            locale: '考核结果',
            requiresAuth: true,
            roles: ['admin'],
            hideInMenu: false
          }
        }
      ]
    },
    {
      path: '/myContractManagement',
      name: 'MyContractManagement',
      meta: {
        locale: '劳动合同',
        requiresAuth: true,
        icon: 'icon-jichushezhi',
        roles: ['admin'],
        hideInMenu: false
      },
      redirect: {
        name: 'MyContract'
      },
      children: [
        {
          path: 'myContract',
          name: 'MyContract',
          component: () =>
            import('@/views/Management/Home/Contract/MyContract/index.vue'),
          meta: {
            locale: '我的合同',
            requiresAuth: true,
            icon: 'icon-jichushezhi',
            roles: ['admin'],
            hideInMenu: false
          }
        },
        {
          path: 'renewalCommunication',
          name: 'RenewalCommunication',
          component: () =>
            import(
              '@/views/Management/Home/Contract/RenewalCommunication/index.vue'
            ),
          meta: {
            locale: '续签沟通',
            requiresAuth: true,
            icon: 'icon-jichushezhi',
            roles: ['admin'],
            hideInMenu: false
          }
        },
        {
          path: 'renewalCommunicationDetails',
          name: 'RenewalCommunicationDetails',
          component: () =>
            import(
              '@/views/Management/Home/Contract/RenewalCommunication/Details/index.vue'
            ),
          meta: {
            locale: '续签沟通详情',
            requiresAuth: true,
            icon: 'icon-jichushezhi',
            roles: ['admin'],
            hideInMenu: true
          }
        },
        {
          path: 'myHandleContract',
          name: 'MyHandleContract',
          component: () =>
            import(
              '@/views/Management/Home/Contract/MyContract/Components/ContractDetail/index.vue'
            ),
          meta: {
            locale: '处理合同',
            requiresAuth: true,
            icon: 'icon-jichushezhi',
            roles: ['admin'],
            hideInMenu: true
          }
        }
        // {
        //   path: 'mySubContract',
        //   name: 'MySubContract',
        //   component: () =>
        //     import('@/views/Management/Home/Contract/SubContract/index.vue'),
        //   meta: {
        //     locale: '下属合同',
        //     requiresAuth: true,
        //     icon: 'icon-jichushezhi',
        //     roles: ['admin'],
        //     hideInMenu: false
        //   }
        // }
      ]
    },
    {
      path: '/myLeaveManagement',
      name: 'MyLeaveManagement',
      meta: {
        locale: '离职管理',
        requiresAuth: true,
        icon: 'icon-jichushezhi',
        roles: ['admin'],
        hideInMenu: false
      },
      redirect: {
        name: 'MyLeaveManagement'
      },
      children: [
        {
          path: 'myLeaveWorkHandover',
          name: 'MyLeaveWorkHandover',
          component: () =>
            import(
              '@/views/Management/Home/LeaveManagement/WorkHandover/index.vue'
            ),
          meta: {
            locale: '工作交接',
            requiresAuth: true,
            icon: 'icon-jichushezhi',
            roles: ['admin'],
            hideInMenu: false
          }
        },
        {
          path: 'myLeaveFormalities',
          name: 'MyLeaveFormalities',
          component: () =>
            import(
              '@/views/Management/Home/LeaveManagement/Formalities/index.vue'
            ),
          meta: {
            locale: '离职手续',
            requiresAuth: true,
            icon: 'icon-jichushezhi',
            roles: ['admin'],
            hideInMenu: false
          }
        },
        {
          path: 'myLeaveHandleWorkHandoverTask',
          name: 'MyLeaveHandleWorkHandoverTask',
          component: () =>
            import(
              '@/views/Management/Home/LeaveManagement/HandleWorkHandoverTask/index.vue'
            ),
          meta: {
            locale: '办理任务',
            requiresAuth: true,
            icon: 'icon-jichushezhi',
            roles: ['admin'],
            hideInMenu: false
          }
        },
        {
          path: 'myLeaveHandleWorkHandoverTaskDetail',
          name: 'MyLeaveHandleWorkHandoverTaskDetail',
          component: () =>
            import(
              '@/views/Management/Home/LeaveManagement/HandleWorkHandoverTask/Components/Detail/index.vue'
            ),
          meta: {
            locale: '离职工作交接详情（办理人）',
            requiresAuth: true,
            icon: 'icon-jichushezhi',
            roles: ['admin'],
            hideInMenu: true
          }
        },
        {
          path: 'myLeaveHandleTask',
          name: 'MyLeaveHandleTask',
          component: () =>
            import(
              '@/views/Management/Home/LeaveManagement/HandleTask/index.vue'
            ),
          meta: {
            locale: '接收任务',
            requiresAuth: true,
            icon: 'icon-jichushezhi',
            roles: ['admin'],
            hideInMenu: false
          }
        },
        {
          path: 'myHandlerLeaveFormalities',
          name: 'MyHandlerLeaveFormalities',
          component: () =>
            import(
              '@/views/Management/Home/LeaveManagement/HandleTask/Components/Formalities/index.vue'
            ),
          meta: {
            locale: '离职手续（办理人）',
            requiresAuth: true,
            icon: 'icon-jichushezhi',
            roles: ['admin'],
            hideInMenu: true
          }
        }
      ]
    },
    {
      path: '/myEntryManagement',
      name: 'MyEntryManagement',
      meta: {
        locale: '入职管理',
        requiresAuth: true,
        icon: 'icon-jichushezhi',
        roles: ['admin'],
        hideInMenu: false
      },
      redirect: {
        name: 'MyEntryFormalities'
      },
      children: [
        {
          path: 'myEntryFormalities',
          name: 'MyEntryFormalities',
          component: () =>
            import(
              '@/views/Management/Home/EntryManagement/Formalities/index.vue'
            ),
          meta: {
            locale: '入职手续',
            requiresAuth: true,
            icon: 'icon-jichushezhi',
            roles: ['admin'],
            hideInMenu: false
          }
        },
        {
          path: 'myEntryHandleTask',
          name: 'MyEntryHandleTask',
          component: () =>
            import(
              '@/views/Management/Home/EntryManagement/HandleTask/index.vue'
            ),
          meta: {
            locale: '接收任务',
            requiresAuth: true,
            icon: 'icon-jichushezhi',
            roles: ['admin'],
            hideInMenu: false
          }
        },
        {
          path: 'myHandlerEntryFormalities',
          name: 'MyHandlerEntryFormalities',
          component: () =>
            import(
              '@/views/Management/Home/EntryManagement/HandleTask/Components/Formalities/index.vue'
            ),
          meta: {
            locale: '入职手续（办理人）',
            requiresAuth: true,
            icon: 'icon-jichushezhi',
            roles: ['admin'],
            hideInMenu: true
          }
        }
      ]
    },
    {
      path: '/myInternshipManagement',
      name: 'MyInternshipManagement',
      meta: {
        locale: '实习管理',
        requiresAuth: true,
        icon: 'icon-jichushezhi',
        roles: ['admin'],
        hideInMenu: false
      },
      redirect: {
        name: 'MyInternshipSummary'
      },
      children: [
        {
          path: 'myInternshipSummary',
          name: 'MyInternshipSummary',
          component: () =>
            import(
              '@/views/Management/Home/InternshipManagement/Summary/index.vue'
            ),
          meta: {
            locale: '实习总结',
            requiresAuth: true,
            icon: 'icon-jichushezhi',
            roles: ['admin'],
            hideInMenu: false
          }
        },
        {
          path: 'myInternshipEvaluation',
          name: 'MyInternshipEvaluation',
          component: () =>
            import(
              '@/views/Management/Home/InternshipManagement/Evaluation/index.vue'
            ),
          meta: {
            locale: '实习评价',
            requiresAuth: true,
            icon: 'icon-jichushezhi',
            roles: ['admin'],
            hideInMenu: false
          }
        }
      ]
    },
    {
      path: '/myIssueCertificate',
      name: 'MyIssueCertificate',
      component: () =>
        import('@/views/Management/Home/IssueCertificate/index.vue'),
      meta: {
        locale: '开具证明',
        requiresAuth: true,
        icon: 'icon-jichushezhi',
        roles: ['admin'],
        hideInMenu: false
      }
    }
  ]
};

export default HOME;
