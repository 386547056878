/* 存放薪酬相关路由 */

import { DEFAULT_LAYOUT } from '../base';
// import DEFAULT_LAYOUT from '@/layout/default-layout.vue'
import { AppRouteRecordRaw } from '../types';
import salaryBaseSettingIndexSubRoutes from './salary/baseSetting';
import homeSub from './homeSub';

const CONTRACT: AppRouteRecordRaw = {
  path: '/salary',
  name: 'Salary',
  component: DEFAULT_LAYOUT,
  meta: {
    locale: '薪酬',
    requiresAuth: true,
    icon: 'icon-home',
    order: 5,
    hideInMenu: false,
    key: '6',
    roles: ['admin']
  },
  redirect: {
    name: 'SalaryBaseSetting'
  },
  children: [
    ...homeSub,
    {
      path: 'salaryBaseSetting',
      name: 'SalaryBaseSetting',
      component: () =>
        import('@/views/Management/Salary/BaseSetting/index.vue'),
      meta: {
        locale: '基础设置',
        requiresAuth: true,
        icon: 'icon-jichushezhi',
        roles: ['*'],
        hideInMenu: false
      }
    },
    {
      path: 'salaryPlan',
      name: 'SalaryPlan',
      component: () => import('@/views/Management/Salary/Plan/index.vue'),
      meta: {
        locale: '薪资方案',
        requiresAuth: true,
        icon: 'icon-jichushezhi',
        roles: ['*'],
        hideInMenu: false
      },
      // redirect: {
      //   name: 'SalaryPlanSetting'
      // },
      children: [
        // {
        //   path: 'salaryPlanSetting',
        //   name: 'SalaryPlanSetting',
        //   component: () => import('@/views/Management/Salary/Plan/index.vue'),
        //   meta: {
        //     locale: '薪资方案',
        //     requiresAuth: true,
        //     icon: 'icon-jichushezhi',
        //     roles: ['*'],
        //     hideInMenu: true
        //   }
        // },
      ]
    },
    {
      path: 'salaryPlanDetail',
      name: 'SalaryPlanDetail',
      component: () =>
        import('@/views/Management/Salary/Plan/Detail/index.vue'),
      meta: {
        locale: '薪资方案详情',
        requiresAuth: true,
        icon: 'icon-jichushezhi',
        roles: ['*'],
        hideInMenu: true
      }
    },
    {
      path: 'fixedSalaryAdjustment',
      name: 'FixedSalaryAdjustment',
      component: () =>
        import('@/views/Management/Salary/FixedSalaryAdjustment/index.vue'),
      meta: {
        locale: '定薪调薪',
        requiresAuth: true,
        icon: 'icon-jichushezhi',
        roles: ['*'],
        hideInMenu: false
      }
    },
    {
      path: 'socialSecurityAndHousingFund',
      name: 'SocialSecurityAndHousingFund',
      meta: {
        locale: '社保和公积金',
        requiresAuth: true,
        icon: 'icon-jichushezhi',
        roles: ['*'],
        hideInMenu: false
      },
      redirect: {
        name: 'SocialSecurityAndHousingFund'
      },
      children: [
        {
          path: 'socialSecurity',
          name: 'SocialSecurity',
          component: () =>
            import(
              '@/views/Management/Salary/SocialSecurityAndHousingFund/SocialSecurity/index.vue'
            ),
          meta: {
            locale: '社保方案',
            requiresAuth: true,
            icon: 'icon-jichushezhi',
            roles: ['*'],
            hideInMenu: false
          }
        },
        {
          path: 'customSocialSecurity',
          name: 'CustomSocialSecurity',
          component: () =>
            import(
              '@/views/Management/Salary/SocialSecurityAndHousingFund/SocialSecurity/CustomSocialSecurity/index.vue'
            ),
          meta: {
            locale: '自定义险种',
            requiresAuth: true,
            icon: 'icon-jichushezhi',
            roles: ['*'],
            hideInMenu: true
          }
        },
        {
          path: 'accumulationFund',
          name: 'AccumulationFund',
          component: () =>
            import(
              '@/views/Management/Salary/SocialSecurityAndHousingFund/AccumulationFund/index.vue'
            ),
          meta: {
            locale: '公积金方案',
            requiresAuth: true,
            icon: 'icon-jichushezhi',
            roles: ['*'],
            hideInMenu: false
          }
        },
        {
          path: 'customAccumulationFund',
          name: 'CustomAccumulationFund',
          component: () =>
            import(
              '@/views/Management/Salary/SocialSecurityAndHousingFund/AccumulationFund/CustomAccumulationFund/index.vue'
            ),
          meta: {
            locale: '自定义险种',
            requiresAuth: true,
            icon: 'icon-jichushezhi',
            roles: ['*'],
            hideInMenu: true
          }
        },
        {
          path: 'annuity',
          name: 'Annuity',
          component: () =>
            import(
              '@/views/Management/Salary/SocialSecurityAndHousingFund/Annuity/index.vue'
            ),
          meta: {
            locale: '年金方案',
            requiresAuth: true,
            icon: 'icon-jichushezhi',
            roles: ['*'],
            hideInMenu: false
          }
        },
        {
          path: 'customAnnuity',
          name: 'CustomAnnuity',
          component: () =>
            import(
              '@/views/Management/Salary/SocialSecurityAndHousingFund/Annuity/CustomAnnuity/index.vue'
            ),
          meta: {
            locale: '自定义险种',
            requiresAuth: true,
            icon: 'icon-jichushezhi',
            roles: ['*'],
            hideInMenu: true
          }
        },
        {
          path: 'insuranceRecords',
          name: 'InsuranceRecords',
          component: () =>
            import(
              '@/views/Management/Salary/SocialSecurityAndHousingFund/InsuranceRecords/index.vue'
            ),
          meta: {
            locale: '参保档案',
            requiresAuth: true,
            icon: 'icon-jichushezhi',
            roles: ['*'],
            hideInMenu: false
          }
        },
        {
          path: 'insuranceBill',
          name: 'InsuranceBill',
          component: () =>
            import(
              '@/views/Management/Salary/SocialSecurityAndHousingFund/InsuranceBill/index.vue'
            ),
          meta: {
            locale: '参保费用明细',
            requiresAuth: true,
            icon: 'icon-jichushezhi',
            roles: ['*'],
            hideInMenu: false
          }
        },
        {
          path: 'insuranceBillDetails',
          name: 'InsuranceBillDetails',
          component: () =>
            import(
              '@/views/Management/Salary/SocialSecurityAndHousingFund/InsuranceBill/Details/index.vue'
            ),
          meta: {
            locale: '参保账单详情',
            requiresAuth: true,
            icon: 'icon-jichushezhi',
            roles: ['*'],
            hideInMenu: true
          }
        }
      ]
    },

    {
      path: 'salarySlip',
      name: 'SalarySlip',
      meta: {
        locale: '工资条管理',
        requiresAuth: true,
        icon: 'icon-jichushezhi',
        roles: ['*'],
        hideInMenu: false
      },
      redirect: {
        name: 'SalarySlipTemplate'
      },
      children: [
        {
          path: 'salarySlipTemplate',
          name: 'SalarySlipTemplate',
          component: () =>
            import('@/views/Management/Salary/SalarySlip/Template/index.vue'),
          meta: {
            locale: '工资条模板',
            requiresAuth: true,
            icon: 'icon-jichushezhi',
            roles: ['*'],
            hideInMenu: false
          }
        },
        {
          path: 'salarySlipSettings',
          name: 'SalarySlipSettings',
          component: () =>
            import(
              '@/views/Management/Salary/SalarySlip/Template/Details/index.vue'
            ),
          meta: {
            locale: '工资条设置',
            requiresAuth: true,
            icon: 'icon-jichushezhi',
            roles: ['*'],
            hideInMenu: true
          }
        }
      ]
    },
    {
      path: 'MonthSalary',
      name: 'monthSalary',
      component: () =>
        import('@/views/Management/Salary/MonthSalary/index.vue'),
      meta: {
        locale: '月工资表',
        requiresAuth: true,
        icon: 'icon-jichushezhi',
        roles: ['*'],
        hideInMenu: false
      }
    },
    {
      path: 'salaryPayroll',
      name: 'SalaryPayroll',
      component: () => import('@/views/Management/Salary/Payroll/index.vue'),
      meta: {
        locale: '工资发放',
        requiresAuth: true,
        icon: 'icon-jichushezhi',
        roles: ['*'],
        hideInMenu: false
      }
    },
    {
      path: 'employeeSalary',
      name: 'EmployeeSalary',
      component: () =>
        import('@/views/Management/Salary/EmployeeSalary/index.vue'),
      meta: {
        locale: '员工薪资',
        requiresAuth: true,
        icon: 'icon-jichushezhi',
        roles: ['*'],
        hideInMenu: false
      }
    },
    {
      path: 'salaryStatement',
      name: 'SalaryStatement',
      component: () =>
        import('@/views/Management/Salary/SalaryStatement/index.vue'),
      meta: {
        locale: '薪酬报表',
        requiresAuth: true,
        icon: 'icon-jichushezhi',
        roles: ['*'],
        hideInMenu: false
      }
    },
    {
      path: 'salaryAnalyze',
      name: 'SalaryAnalyze',
      component: () =>
        import('@/views/Management/Salary/SalaryAnalyze/index.vue'),
      meta: {
        locale: '薪酬分析',
        requiresAuth: true,
        icon: 'icon-jichushezhi',
        roles: ['*'],
        hideInMenu: false
      }
    },
    {
      path: 'salaryYearEndBonus',
      name: 'SalaryYearEndBonus',
      component: () =>
        import('@/views/Management/Salary/YearEndBonus/index.vue'),
      meta: {
        locale: '年终奖计算',
        requiresAuth: true,
        icon: 'icon-jichushezhi',
        roles: ['*'],
        hideInMenu: false
      }
    },
    {
      path: 'salaryPaySheet',
      name: 'SalaryPaySheet',
      component: () => import('@/views/Management/Salary/PaySheet/index.vue'),
      meta: {
        locale: '发送工资条',
        requiresAuth: true,
        icon: 'icon-jichushezhi',
        roles: ['*'],
        hideInMenu: false
      }
    },
    ...salaryBaseSettingIndexSubRoutes
  ]
};

export default CONTRACT;
