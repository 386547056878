/* 自定义日历组件的store */
import { defineStore } from 'pinia';
import { showCal } from '@/utils/calender';  // 农历
import { calTime, calTimeStamp } from '@/utils/utils';  // 时间运算
import dayjs from 'dayjs'
import { Message } from '@arco-design/web-vue';
import { REMIND_TIME } from '@/utils/constants';
import { PostMyScheDule, GetMyScheDule, DelMyScheDule, PutMyScheDule } from '@/apis/management/home/myCalendar'

const useCustomCalendarStore = defineStore('customCalendar', {
  // state定义所有的状态
  state: () => ({
    userId: Number(localStorage.getItem('userId')),
    // 类型名字
    typeName: '',
    // 表示 周 月 年
    modelIndex: 0,
    // 当前周的日期
    day: [],
    // 农历周日期
    lunar: [],
    // 需要跳转到 X 周 ，-7就是上一周，默认 0 本周
    cycle: 0,
    // 当前 默认选中 的 日期
    selectCalendar: dayjs().format("YYYY-MM-DD"),
    // 选中日期
    dateValue: '',
    // 当前日程待办模型
    // 是否可见
    scheduleType: false,
    // 面访类型列表
    typeList: [
      {
        name: '销售面访',
        value: 1
      },
      {
        name: '个人面访',
        value: 2
      },
      {
        name: '增员面访',
        value: 3
      },
      {
        name: '其它',
        value: 4
      },
      {
        name: '活动',
        value: 5
      },
      {
        name: '保单周年日',
        value: 6
      },
      {
        name: '生日',
        value: 7
      },
      {
        name: '待办',
        value: 8
      },
      {
        name: '会议',
        value: 9
      },
      {
        name: '培训',
        value: 10
      }
    ],
    // 固定周 装一周的事件
    weekName: ([
      {
        name: '周日',
        event: []
      },
      {
        name: '周一',
        event: []
      },
      {
        name: '周二',
        event: []
      },
      {
        name: '周三',
        event: []
      },
      {
        name: '周四',
        event: []
      },
      {
        name: '周五',
        event: []
      },
      {
        name: '周六',
        event: []
      }
    ]),

    // 选中的面访类型列表
    selectTypeList: [],
    // 选项列表
    interviewClassList: [
      {
        name: '面访类型1',
        value: 1
      },
      {
        name: '面访类型2',
        value: 2
      },
      {
        name: '面访类型3',
        value: 3
      },
      {
        name: '面访类型4',
        value: 4
      },
      {
        name: '面访类型5',
        value: 5
      }
    ],
    remindTimeList: REMIND_TIME,
    // 颜色列表
    colourEnums: [
      '#ffe9e9',
      '#e0f8ec',
      '#ece7fc',
      '#d5f7f9',
      '#fff6db',
      '#92d9d9',
      '#a3d5ff'
    ],
    // 是否选择了颜色
    isColourCheck: false,
    // 事件面访表单数据
    personalData: {
      type: '销售面访', // 暂定只有一种事件
      form: '线下',
      name: '', // 面访标题
      customerName: '', // 客户姓名
      interviewType: '', // 面访类型
      startTime: '', // 开始时间
      endTime: '', // 结束时间
      address: '', // 详细地址
      interviewAddress: '', // 面访地址
      remind: '', // 提醒分钟
      remindTime: '', // 提醒时间
      colour: '', // 颜色
      remark: '', // 备注
      userId: Number(localStorage.getItem('userId')), //用户id
      fileIds: '' // 文件
    },
    // 选择列表的开始时间
    startTime: '',
    // 选择列表的结束时间
    endTime: '',
    // 事件列表
    eventList: [],
    // 选中的事件列表(周)
    selectEventList: [],
  }),
  // 所有方法
  actions: {
    // 日期日历 本周日期
    handleFill() {
      // 获取本周的起始日期
      let time = dayjs(dayjs().day(this.cycle)).startOf('week')
      // 用于存储 本周所有的日期
      let weekArr = []
      let lunarArr = []
      for (let i = 0; i < 7; i++) {
        let tempTime = dayjs(time).day(i)
        let tr = dayjs(tempTime).format("YYYY-MM-DD") // "2021-11-08 (星期一)"
        let lr = showCal(new Date(tr)).lunarMonth + '月' + '' + showCal(new Date(tr)).lunarDay
        weekArr.push(tr)
        lunarArr.push(lr)
      }
      // 将本周的所有日期 保存
      this.day = weekArr
      this.lunar = lunarArr
    },
    // 新增'| 编辑 日程待办
    addNewSchedule(type: { name: string, value: number }) {
      if (type.value === 1) {
        this.typeName = type.name
        this.scheduleType = true
      }

    },
    // 取消新增
    handleCancel() {
      // 初始化表单
      this.personalData = {
        type: '销售面访', // 暂定只有一种事件
        form: '线下',
        name: '', // 面访标题
        customerName: '', // 客户姓名
        interviewType: '', // 面访类型
        startTime: '', // 开始时间
        endTime: '', // 结束时间
        address: '', // 详细地址
        interviewAddress: '', // 面访地址
        remind: '', // 提醒
        remindTime: '', // 提醒时间
        colour: '', // 颜色
        remark: '', // 备注
        userId: Number(localStorage.getItem('userId')), //用户id
        fileIds: '' // 文件
      }
      this.scheduleType = false
    },
    // 提交新增日程
    async handleOK() {
      let { personalData } = this
      let { type, form, name, customerName, interviewType, startTime, endTime, address, interviewAddress, remind, remindTime, colour, remark, userId, fileIds } = personalData
      // 表单校验
      if (colour === '') {
        colour = this.colourEnums[Math.floor(Math.random() * this.colourEnums.length)]
      }
      if (name.trim() === '') {
        return Message.warning('请填写面访标题');
      }
      if (customerName.trim() === '') {
        return Message.warning('请填写客户姓名');
      }
      if (interviewType.trim() === '') {
        return Message.warning('请选择面访类型');
      }
      if (!startTime) {
        return Message.warning('请选择开始时间');
      }
      if (!endTime) {
        return Message.warning('请选择结束时间');
      }
      // 参数整理
      startTime = this.formatTime(startTime); // 开始结束时间要放在提醒时间的判断前面 
      endTime = this.formatTime(endTime);
      // 提醒时间判断
      if (!remind) {
        remindTime = startTime
      } else {
        remindTime = this.calRemindTime(startTime, Number(remind))
      }
      let temp = { // 参数整理
        type,
        form,
        name,
        customerName,
        interviewType,
        startTime,
        endTime,
        address,
        interviewAddress,
        remindTime,
        colour,
        remark,
        userId,
        fileIds,
      }
      try {
        if (personalData.id) {
          temp.id = personalData.id
          await PutMyScheDule(temp)
        } else {
          await PostMyScheDule(temp)
        }
      } catch (error: unknown) {
        console.log(error);
      }
      this.handleCancel()
      // 周修改 列表的显示
      if(this.modelIndex === 0) {
        this.getDataList(this.day[0],this.day[this.day.length - 1])
      } 
      // 月修改
      else if (this.modelIndex === 1) {
        
      }
    },
    // 重置时间
    resetEndTime() {
      this.personalData.endTime = this.personalData.startTime
    },
    // 格式化时间 用于返回适合后端的数据
    formatTime(time: string) {
      let timeArr = time.split(' ')
      if (timeArr.length === 2) {
        timeArr[0] += 'T'
        timeArr[1] += ':00Z'
        return timeArr.join('')
      } else {
        return time
      }

    },
    // 计算时间差( 天数 - 分钟)
    calRemindTime(day: string, min: number) {
      let time = new Date(day).getTime() - min * 60000
      return new Date(time).toISOString()
    },
    // 选择颜色
    // 选中系统已有的颜色
    isCheckColour(colour: string) {
      this.personalData.colour = colour;
      this.isColourCheck = true;
    },
    // 获取日程列表
    async getDataList(startTime:any,endTime:any) {
      
      try {
        // 参数整理 
        // 因为后端返回的UTC时区的缘故 请求时间要+8小时
     
        // 第一天
        // this.startTime = calTime(`${this.day[0]}T00:00:00Z`, 28800000)
        this.startTime = calTime(`${startTime}T00:00:00Z`, 28800000)
        // 最后一天
        // this.endTime = calTime(`${this.day[this.day.length - 1]}T23:59:59Z`, 28800000)
        this.endTime = calTime(`${endTime}T23:59:59Z`, 28800000)

        const res = await GetMyScheDule({
          userId: this.userId,
          startTime: this.startTime,
          endTime: this.endTime,
        })
        this.eventList = res
        this.eventList.forEach((item: { startTime: string, endTime: string, remindTime: string }) => {
          if (item.startTime && item.endTime) {
            item.startTime = new Date(item.startTime).toISOString()
            item.endTime = new Date(item.endTime).toISOString()
            item.remindTime = new Date(item.remindTime).toISOString()
          }
        })
  
        // 筛选出需要展示的
        if (this.eventList[0]) {
          this.selectEventList = this.eventList.filter((event: { type: string }) => {
            return this.selectTypeList.findIndex((item: { name: string }) => item.name === event.type) !== -1
          })
          // 列表排序
          this.selectEventList = this.selectEventList.sort((a: { id: number }, b: { id: number }) => {
            return b.id - a.id
          })
  
        } else {
          this.selectEventList = []
        }

      } catch (error: unknown) {
        console.log(error);
      }
    },
    // 事件列表类型筛选
    handleChange(checkedKey: [{ value: number }]) {
      this.selectTypeList = []
      if (checkedKey[0]) {
        checkedKey.forEach(item => {
          this.selectTypeList.push(this.typeList.find(type => type.value === item))
        })
      } else {
        this.selectTypeList = []
      }
      // 按周显示
      if (this.eventList[0]) {
        this.selectEventList = this.eventList.filter((event: { type: string }) => {
          return this.selectTypeList.findIndex((item: { name: string }) => item.name === event.type) !== -1
        })
        // 列表排序
        this.selectEventList = this.selectEventList.sort((a: { id: number }, b: { id: number }) => {
          return b.id - a.id
        })
      } 

      else {
        this.selectEventList = []
      }

    },
    // 删除选中的事件
    async del(item: { id: number }) {
      try {
        await DelMyScheDule({
          id: item.id
        })
      } catch (error: unknown) {
        console.log(error);

      }
    },
    // 编辑事件
    edit(item: any) {
      // if (item.type === '个人面访') {
        Object.assign(this.personalData, item)
        // 将提醒时间还原成分钟
        if (item.startTime === item.remindTime) {
          this.personalData.remind = REMIND_TIME.find((remindITem) => remindITem.time === 0)?.time
        } else {
          let time = calTimeStamp(item.startTime, item.remindTime) / 60000
          this.personalData.remind = REMIND_TIME.find((remindITem) => remindITem.time === time)?.time

        }
      // }
    },
 
    // 根据时间 显示日程
    filterschedule() {
      // 将对应的事件 按照发生的时间放进数组之中
      // 初始化数组
      for (let i = 0; i < 7; i++) {
        this.weekName[i].event = []
      }
      // 一周七天
      for (let i = 0; i < 7; i++) {
        // 一天24小时
        for (let j = 1; j <= 24; j++) {
          let date = `${this.day[i]}T${j >= 10 ? j : '0' + j}`
          let currentEvent = {}
          let flag = this.selectEventList.some((event: any) => {
            // 同一个小时就显示出来
            if (event?.startTime?.slice(0, 13) === date.slice(0, 13)) {
              // 整理参数
              currentEvent = {
                id: event.id,
                title: `${event.startTime?.slice(11, 16)} ${event.type} | ${event.interviewType}: ${event.name}`,
                colour: event.colour,
                // 距离父级时间框的距离
                top: Number(70 * (event.startTime?.slice(14, 16)/60)) + 'px'
              }
              
              return true
            }
          })
          if (flag) {
            this.weekName[i].event[j] = currentEvent
          }

        }
      }

    }
  }
});

export default useCustomCalendarStore;
