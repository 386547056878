/* 存放组织相关路由 */

import { DEFAULT_LAYOUT } from '../base';
// import DEFAULT_LAYOUT from '@/layout/default-layout.vue'
import { AppRouteRecordRaw } from '../types';
import organizationalBaseSettingSubRoutes from './organization/baseSetting';
import organizationalManagementSubRoutes from './organization/organizationalManagement';
import organizationalPositionSubRoutes from './organization/positionManagement';
import homeSub from './homeSub';

const ORGANIZATION: AppRouteRecordRaw = {
  path: '/organization',
  name: 'organization',
  component: DEFAULT_LAYOUT,
  meta: {
    locale: '组织',
    requiresAuth: true,
    icon: 'icon-home',
    order: 1,
    hideInMenu: false,
    key: '2',
    roles: ['admin']
  },
  redirect: {
    name: 'OrganizationalBaseSetting'
  },
  children: [
    ...homeSub,
    {
      path: 'organizationalBaseSetting',
      name: 'OrganizationalBaseSetting',
      component: () =>
        import('@/views/Management/Organization/BaseSetting/index.vue'),
      meta: {
        locale: '基础设置',
        requiresAuth: true,
        icon: 'icon-jichushezhi',
        roles: ['admin'],
        hideInMenu: false
      }
    },
    ...organizationalBaseSettingSubRoutes,
    {
      path: 'organizationalManagement',
      name: 'OrganizationalManagement',
      meta: {
        locale: '机构管理',
        requiresAuth: true,
        icon: 'icon-zuzhijigouguanli',
        roles: ['admin'],
        hideInMenu: false
      },
      children: [
        {
          path: 'omStructure',
          name: 'OmStructure',
          component: () =>
            import(
              '@/views/Management/Organization/OrganizationManagement/Structure/index.vue'
            ),
          meta: {
            locale: '组织机构',
            requiresAuth: true,
            roles: ['admin'],
            hideInMenu: false
          }
        },
        {
          path: 'omStructurePic',
          name: 'OmStructurePic',
          component: () =>
            import(
              '@/views/Management/Organization/OrganizationManagement/StructurePic/index.vue'
            ),
          meta: {
            locale: '组织架构图',
            requiresAuth: true,
            roles: ['*'],
            hideInMenu: false
          }
        },
        {
          path: 'leGalentity',
          name: 'LeGalentity',
          component: () =>
            import(
              '@/views/Management/Organization/OrganizationManagement/LegalEntity/index.vue'
            ),
          meta: {
            locale: '法律实体',
            requiresAuth: true,
            roles: ['*'],
            hideInMenu: false
          }
        },
        {
          path: 'organizationWorkPlace',
          name: 'OrganizationWorkPlace',
          component: () =>
            import(
              '@/views/Management/Organization/OrganizationManagement/WorkPlace/index.vue'
            ),
          meta: {
            locale: '工作地点',
            requiresAuth: true,
            roles: ['*'],
            hideInMenu: false
          }
        },
        {
          path: 'reportingRelationship',
          name: 'ReportingRelationship',
          component: () =>
            import(
              '@/views/Management/Organization/OrganizationManagement/ReportingRelationship/index.vue'
            ),
          meta: {
            locale: '汇报关系',
            requiresAuth: true,
            roles: ['*'],
            hideInMenu: false
          }
        },
        {
          path: 'photoWall',
          name: 'PhotoWall',
          component: () =>
            import(
              '@/views/Management/Organization/OrganizationManagement/PhotoWall/index.vue'
            ),
          meta: {
            locale: '照片墙',
            requiresAuth: true,
            roles: ['*'],
            hideInMenu: false
          }
        },
        ...organizationalManagementSubRoutes
      ]
    },

    {
      path: 'positionManagement',
      name: 'PositionManagement',
      meta: {
        locale: '职位管理',
        requiresAuth: true,
        icon: 'icon-zhiweiguanli',
        roles: ['admin'],
        hideInMenu: false
      },
      children: [
        {
          path: 'systemStandManagement',
          name: 'SystemStandManagement',
          component: () =>
            import(
              '@/views/Management/Organization/PositionManagement/SystemStandManagement/index.vue'
            ),
          meta: {
            locale: '标准职位',
            requiresAuth: true,
            roles: ['*'],
            hideInMenu: false
          }
        },
        {
          path: 'systemManagement',
          name: 'SystemManagement',
          component: () =>
            import(
              '@/views/Management/Organization/PositionManagement/SystemManagement/index.vue'
            ),
          meta: {
            locale: '职位体系',
            requiresAuth: true,
            roles: ['*'],
            hideInMenu: false
          }
        },
        {
          path: 'jobManagement',
          name: 'JobManagement',
          component: () =>
            import(
              '@/views/Management/Organization/PositionManagement/JobManagement/index.vue'
            ),
          meta: {
            locale: '职务管理',
            requiresAuth: true,
            roles: ['*'],
            hideInMenu: false
          }
        },
        ...organizationalPositionSubRoutes
      ]
    }
  ]
};

export default ORGANIZATION;
