/*
 * @Author: 陈俊升 761723995@qq.com
 * @Date: 2023-05-11 08:47:22
 * @LastEditors: 陈俊升 761723995@qq.com
 * @LastEditTime: 2024-03-14 09:43:01
 * @FilePath: /hr_new_vue3/src/router/routes/management/organization/organizationalManagement/index.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/* 储存组织机构管理相关子路由（不显示在侧边导航栏的路由） */
const organizationalManagementSubRoutes: any = [
  /* 历史快照记录 */
  {
    path: 'organizationDetail',
    name: 'OrganizationDetail',
    component: () =>
      import(
        '@/views/Management/Organization/OrganizationManagement/Structure/FileDetail/index.vue'
      ),
    meta: {
      locale: '组织详情',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true,
      ignoreCache: false
    }
  },
  /* 历史快照记录 */
  {
    path: 'historicalSnapshot',
    name: 'HistoricalSnapshot',
    component: () =>
      import(
        '@/views/Management/Organization/OrganizationManagement/Structure/HistoricalSnapshot/index.vue'
      ),
    meta: {
      locale: '历史快照记录',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'historicalSnapshotDetail/:id',
    name: 'HistoricalSnapshotDetail',
    component: () =>
      import(
        '@/views/Management/Organization/OrganizationManagement/Structure/HistoricalSnapshot/Components/Detail/index.vue'
      ),
    meta: {
      locale: '历史快照详情',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  /* 变更记录记录 */
  {
    path: 'changeRecord',
    name: 'ChangeRecord',
    component: () =>
      import(
        '@/views/Management/Organization/OrganizationManagement/Structure/ChangeRecord/index.vue'
      ),
    meta: {
      locale: '变更记录',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'moveOrMergeHistory/:id/:type',
    name: 'MoveOrMergeHistory',
    component: () =>
      import(
        '@/views/Management/Organization/OrganizationManagement/Structure/MoveMergeHistory/index.vue'
      ),
    meta: {
      locale: '移动/合并历史',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'organizationPersonChange/:id/:type',
    name: 'OrganizationPersonChange',
    component: () =>
      import(
        '@/views/Management/Organization/OrganizationManagement/Structure/MoveMergeHistory/PersonChange/index.vue'
      ),
    meta: {
      locale: '组织人事异动',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'organizationPersonChangeDetail/:id/:type',
    name: 'OrganizationPersonChangeDetail',
    component: () =>
      import(
        '@/views/Management/Organization/OrganizationManagement/Structure/MoveMergeHistory/Detail/index.vue'
      ),
    meta: {
      locale: '组织人事异动详情',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'organizationPersonManagement/:id/:name',
    name: 'OrganizationPersonManagement',
    component: () =>
      import(
        '@/views/Management/Organization/OrganizationManagement/Structure/FileDetail/Components/PersonManagement/index.vue'
      ),
    meta: {
      locale: '组织内人员管理',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'organization/import',
    name: 'OrganizationImport',
    component: () =>
      import(
        '@/views/Management/Organization/OrganizationManagement/Structure/Import/index.vue'
      ),
    meta: {
      locale: '组织导入',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  }
];

export default organizationalManagementSubRoutes;
