/* 存放人事相关路由 */

import { DEFAULT_LAYOUT } from '../base';
// import DEFAULT_LAYOUT from '@/layout/default-layout.vue'
import { AppRouteRecordRaw } from '../types';
import humanBaseSettingSubRoutes from './human/baseSetting';
import humanRosterSubRoutes from './human/humanRoster';
import humanChangeManagementSubRoutes from './human/changeManagement';
import humanExamineSubRoutes from './human/humanExamine';
import homeSub from './homeSub';

const HUMAN: AppRouteRecordRaw = {
  path: '/human',
  name: 'human',
  component: DEFAULT_LAYOUT,
  meta: {
    locale: '人事',
    requiresAuth: true,
    icon: 'icon-home',
    order: 2,
    hideInMenu: false,
    key: '3'
  },
  redirect: {
    name: 'HumanBaseSetting'
  },
  children: [
    ...homeSub,
    {
      path: 'humanBaseSetting',
      name: 'HumanBaseSetting',
      component: () => import('@/views/Management/Human/BaseSetting/index.vue'),
      meta: {
        locale: '基础设置',
        requiresAuth: true,
        icon: 'icon-jichushezhi',
        roles: ['*'],
        hideInMenu: false
      }
    },
    ...humanBaseSettingSubRoutes,
    {
      path: 'humanRoster',
      name: 'HumanRoster',
      component: () => import('@/views/Management/Human/HumanRoster/index.vue'),
      meta: {
        locale: '人员花名册',
        requiresAuth: true,
        icon: 'icon-renyuanhuamingce',
        roles: ['*'],
        hideInMenu: false
      }
    },
    {
      path: 'IssuingCertificate',
      name: 'IssuingCertificate',
      component: () =>
        import(
          '@/views/Management/Human/HumanRoster/IssuingCertificate/index.vue'
        ),
      meta: {
        locale: '员工开具证明',
        requiresAuth: true,
        icon: 'icon-renyuanhuamingce',
        roles: ['*'],
        hideInMenu: true
      }
    },
    ...humanRosterSubRoutes,
    {
      path: 'changeManagement',
      name: 'ChangeManagement',
      meta: {
        locale: '人事管理',
        requiresAuth: true,
        icon: 'icon-renshiguanli',
        roles: ['*'],
        hideInMenu: false
      },
      children: [
        {
          path: 'inductionManagement',
          name: 'InductionManagement',
          meta: {
            locale: '入职管理',
            requiresAuth: true,
            roles: ['*'],
            hideInMenu: false
          },
          children: [
            // {
            //   path: 'entryApplication',
            //   name: 'EntryApplication',
            //   component: () =>
            //     import(
            //       '@/views/Management/Human/ChangeManagement/InductionManagement/EntryApplication/index.vue'
            //     ),
            //   meta: {
            //     locale: '入职申请',
            //     requiresAuth: true,
            //     roles: ['*'],
            //     hideInMenu: false
            //   }
            // },
            {
              path: 'addNewemployee',
              name: 'AddNewemployee',
              component: () =>
                import(
                  '@/views/Management/Human/ChangeManagement/InductionManagement/AddNewemployee/index.vue'
                ),
              meta: {
                locale: '添加新员工',
                requiresAuth: true,
                roles: ['*'],
                hideInMenu: false
              }
            },
            {
              path: 'entryApplication',
              name: 'EntryApplication',
              component: () =>
                import(
                  '@/views/Management/Human/ChangeManagement/InductionManagement/EntryApplication/index.vue'
                ),
              meta: {
                locale: '入职申请列表',
                requiresAuth: true,
                roles: ['*'],
                hideInMenu: false
              }
            },
            {
              path: 'waitManagement',
              name: 'WaitManagement',
              component: () =>
                import(
                  '@/views/Management/Human/ChangeManagement/InductionManagement/WaitManagement/index.vue'
                ),
              meta: {
                locale: '待入职列表',
                requiresAuth: true,
                roles: ['*'],
                hideInMenu: false
              }
            },
            {
              path: 'preentryManagement',
              name: 'PreentryManagement',
              component: () =>
                import(
                  '@/views/Management/Human/ChangeManagement/InductionManagement/PreentryManagement/index.vue'
                ),
              meta: {
                locale: '预入职列表',
                requiresAuth: true,
                roles: ['*'],
                hideInMenu: false
              }
            },
            {
              path: 'entryManagement',
              name: 'EntryManagement',
              component: () =>
                import(
                  '@/views/Management/Human/ChangeManagement/InductionManagement/EntryManagement/index.vue'
                ),
              meta: {
                locale: '入职办理列表',
                requiresAuth: true,
                roles: ['*'],
                hideInMenu: false
              }
            },
            {
              path: 'entryManagement/approval',
              name: 'EntryManagementApproval',
              component: () =>
                import(
                  '@/views/Management/Human/ChangeManagement/InductionManagement/EntryManagement/Approval/index.vue'
                ),
              meta: {
                locale: '入职审批',
                requiresAuth: true,
                roles: ['*'],
                hideInMenu: true
              }
            },
            {
              path: 'abandonmentEntry',
              name: 'AbandonmentEntry',
              component: () =>
                import(
                  '@/views/Management/Human/ChangeManagement/InductionManagement/AbandonmentEntry/index.vue'
                ),
              meta: {
                locale: '放弃入职列表',
                requiresAuth: true,
                roles: ['*'],
                hideInMenu: false
              }
            }
          ]
        },

        {
          path: 'internshipManagement',
          name: 'InternshipManagement',
          meta: {
            locale: '实习管理',
            requiresAuth: true,
            roles: ['*'],
            hideInMenu: false
          },
          redirect: {
            name: 'InternshipEvaluation'
          },
          children: [
            {
              path: 'internshipEvaluation',
              name: 'InternshipEvaluation',
              component: () =>
                import(
                  '@/views/Management/Human/ChangeManagement/InternshipManagement/Evaluation/index.vue'
                ),
              meta: {
                locale: '实习列表',
                requiresAuth: true,
                roles: ['*'],
                hideInMenu: false
              }
            },
            {
              path: 'internshipTransfer',
              name: 'InternshipTransfer',
              component: () =>
                import(
                  '@/views/Management/Human/ChangeManagement/InternshipManagement/Transfer/index.vue'
                ),
              meta: {
                locale: '实习调动',
                requiresAuth: true,
                roles: ['*'],
                hideInMenu: false
              }
            },
            {
              path: 'internshipEnd',
              name: 'InternshipEnd',
              component: () =>
                import(
                  '@/views/Management/Human/ChangeManagement/InternshipManagement/End/index.vue'
                ),
              meta: {
                locale: '实习结束',
                requiresAuth: true,
                roles: ['*'],
                hideInMenu: false
              }
            }
          ]
        },
        {
          path: 'probationPeriodManagement',
          name: 'ProbationPeriodManagement',
          meta: {
            locale: '试用期管理',
            requiresAuth: true,
            roles: ['*'],
            hideInMenu: false
          },
          children: [
            {
              path: 'probationPeriodManagementScheme',
              name: 'ProbationPeriodManagementScheme',
              component: () =>
                import(
                  '@/views/Management/Human/ChangeManagement/ProbationPeriodManagement/ProbationScheme/index.vue'
                ),
              meta: {
                locale: '考核方案',
                requiresAuth: true,
                roles: ['*'],
                hideInMenu: false
              }
            }
            // {
            //   path: 'probationPeriodManagementResult',
            //   name: 'ProbationPeriodManagementResult',
            //   component: () =>
            //     import(
            //       '@/views/Management/Human/ChangeManagement/ProbationPeriodManagement/ProbationResult/index.vue'
            //     ),
            //   meta: {
            //     locale: '考核结果',
            //     requiresAuth: true,
            //     roles: ['*'],
            //     hideInMenu: false
            //   }
            // }
          ]
        },
        {
          path: 'confirmationManagement',
          name: 'ConfirmationManagement',
          component: () =>
            import(
              '@/views/Management/Human/ChangeManagement/ConfirmationManagement/index.vue'
            ),
          meta: {
            locale: '转正管理',
            requiresAuth: true,
            roles: ['*'],
            hideInMenu: false
          }
        },
        {
          path: 'leaveManagement',
          name: 'LeaveManagement',
          meta: {
            locale: '离职管理',
            requiresAuth: true,
            roles: ['*'],
            hideInMenu: false
          },
          children: [
            {
              path: 'leaveApply',
              name: 'LeaveApply',
              component: () =>
                import(
                  '@/views/Management/Human/ChangeManagement/LeaveManagement/LeaveApply/index.vue'
                ),
              meta: {
                locale: '离职申请列表',
                requiresAuth: true,
                roles: ['*'],
                hideInMenu: false
              }
            },
            // {
            //   path: 'internshipEndList',
            //   name: 'InternshipEndList',
            //   component: () =>
            //     import(
            //       '@/views/Management/Human/ChangeManagement/LeaveManagement/InternshipEndList/index.vue'
            //     ),
            //   meta: {
            //     locale: '实习结束列表',
            //     requiresAuth: true,
            //     roles: ['*'],
            //     hideInMenu: false
            //   }
            // },
            {
              path: 'waitLeave',
              name: 'WaitLeave',
              component: () =>
                import(
                  '@/views/Management/Human/ChangeManagement/LeaveManagement/WaitLeave/index.vue'
                ),
              meta: {
                locale: '待离职列表',
                requiresAuth: true,
                roles: ['*'],
                hideInMenu: false
              }
            },
            {
              path: 'haveLeave',
              name: 'HaveLeave',
              component: () =>
                import(
                  '@/views/Management/Human/ChangeManagement/LeaveManagement/HaveLeave/index.vue'
                ),
              meta: {
                locale: '已离职列表',
                requiresAuth: true,
                roles: ['*'],
                hideInMenu: false
              }
            }
          ]
        },
        {
          path: 'transactionManagement',
          name: 'TransactionManagement',
          meta: {
            locale: '人事异动',
            requiresAuth: true,
            roles: ['*'],
            hideInMenu: false
          },
          redirect: {
            name: 'TransactionManagementWait'
          },
          children: [
            {
              path: 'transactionManagementWait',
              name: 'TransactionManagementWait',
              component: () =>
                import(
                  '@/views/Management/Human/ChangeManagement/TransactionManagement/WaitTransaction/index.vue'
                ),
              meta: {
                locale: '待异动',
                requiresAuth: true,
                roles: ['*'],
                hideInMenu: false
              }
            },
            {
              path: 'transactionManagementRecord',
              name: 'TransactionManagementRecord',
              component: () =>
                import(
                  '@/views/Management/Human/ChangeManagement/TransactionManagement/TransactionRecord/index.vue'
                ),
              meta: {
                locale: '异动记录',
                requiresAuth: true,
                roles: ['*'],
                hideInMenu: false
              }
            }
          ]
        },
        {
          path: 'blacklistManagement',
          name: 'BlacklistManagement',
          component: () =>
            import(
              '@/views/Management/Human/ChangeManagement/BlacklistManagement/index.vue'
            ),
          meta: {
            locale: '黑名单管理',
            requiresAuth: true,
            roles: ['*'],
            hideInMenu: false
          }
        },
        ...humanChangeManagementSubRoutes
      ]
    }
    // {
    //   path: 'humanExamine',
    //   name: 'HumanExamine',
    //   meta: {
    //     locale: '人事审批',
    //     requiresAuth: true,
    //     icon: 'icon-renshishenpi',
    //     roles: ['*'],
    //     hideInMenu: false
    //   },
    //   children: [
    //     {
    //       path: 'humanExamineForm',
    //       name: 'HumanExamineForm',
    //       component: () =>
    //         import('@/views/Management/Human/HumanExamine/ExamineForm'),
    //       meta: {
    //         locale: '审批表单',
    //         requiresAuth: true,
    //         roles: ['*'],
    //         hideInMenu: false
    //       }
    //     },
    //     ...humanExamineSubRoutes
    //   ]
    // }
  ]
};

export default HUMAN;
