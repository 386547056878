import { defineStore } from 'pinia';
import { postFileUpload } from '@/apis/management/base/index';
import { delFileUploadInfo } from '@/apis/document/documentCenter/index';
import { useNoticeStore } from '@/store/index';
import uniqueid from 'lodash.uniqueid';
import { PostFile } from '@/apis/management/base';
import { postMsgApiMessag } from '@/apis/notice/index';
import router from '@/router';
import dayjs from 'dayjs';
// import { TBaseFileList, TBaseFile } from '~/apis/resource/type';
import {
  addByUploadFileDb,
  delByUploadFileDb,
  getByUploadFileDb
} from '@/utils/indexdb/uploadFileDb';
import { localGetUploadFileList } from '@/utils/localStorage/account';
import {
  imgTypeList,
  officeTypeList,
  vidoeTypeList,
  audioTypeList,
  getVideoDuration,
  getAudioDuration
} from '@/utils/file';

//该store用于文件上传
const useStore = defineStore('uploadFile', {
  state: () => {
    const uploadFileList: any[] = [];
    return {
      uploadFileList: uploadFileList, //文件上传列表
      chunkSize: 5, //切片大小
      successNum: 0, //成功数量
      fileBelongId: undefined,
      uploadModelList: {}
    };
  },
  actions: {
    //新增上传列表数据
    async addUpLoadList(
      file: File,
      fileBelongId?: number,
      route?: string,
      userId?: any,
      fileAttribute?: number,
      shareState?: number,
      type?: string
    ) {
      try {
        const date = new Date();
        const useFileName = file.name;
        const typeName = useFileName.slice(useFileName.lastIndexOf('.'));
        let remark;
        if (vidoeTypeList.includes(typeName))
          remark = await getVideoDuration(file);
        if (audioTypeList.includes(typeName))
          remark = await getAudioDuration(file);
        const pushData: any = {
          useFileName: file.name,
          size: file.size,
          userId,
          file,
          shareState,
          filePath: route,
          fileStart: 0,
          state: 'stop',
          fileType: getFileType(file.name),
          uuid: date.valueOf() + '-' + file.name,
          fileBelongId,
          fileAttribute,
          remark,
          type
        };
        this.uploadFileList.push(pushData);
        const fileData = {
          uuid: pushData.uuid,
          file: pushData.file
        };
        console.log(999, this.uploadFileList);
        await addByUploadFileDb(fileData);
        return Promise.resolve(fileData);
      } catch (err) {
        return Promise.reject();
      }
    },
    //删除上传队列
    async delUpLoadList(uuid: string) {
      const index = this.uploadFileList.findIndex((item) => item.uuid === uuid);
      console.log(this.uploadFileList[index], this.uploadFileList);

      if (
        this.uploadFileList[index].state === 'run' ||
        this.uploadFileList[index].state === 'stop' ||
        this.uploadFileList[index].state === 'forceStop'
      ) {
        await delFileUploadInfo({
          name: this.uploadFileList[index].fileName
        });
      }
      await delByUploadFileDb(this.uploadFileList[index].uuid);
      this.uploadFileList.splice(index, 1);
    },
    //修改文件状态 开始上传或停止
    async edItUploadState(
      uuid: string,
      state: 'run' | 'stop' | 'error',
      arcoFun?: any
    ) {
      const findIndex = this.uploadFileList.findIndex(
        (item) => item.uuid === uuid
      );
      this.uploadFileList[findIndex].state = state;
      if (state === 'run')
        await this.runUpload(this.uploadFileList[findIndex], arcoFun);
    },
    //加载历史上传列表
    async loadUploadFile() {
      const fileList = localGetUploadFileList();
      this.getUploadBreakChunk();
      this.uploadFileList = [];
      for (const iterator of fileList) {
        const pushData: any = {
          ...iterator
        };
        if (iterator.fileStart < iterator.size) {
          const fileItem = await getByUploadFileDb(iterator.uuid);
          pushData.file = fileItem.file;
        }
        this.uploadFileList.push(pushData);
      }
    },
    //开始上传
    async runUpload(fileItem: any, arcoFun?: any) {
      console.log(3, arcoFun);
      await this.getUploadBreakChunk();
      const {
        fileStart,
        file,
        uuid,
        size,
        fileType,
        filePath,
        fileBelongId,
        remark,
        userId,
        useFileName,
        fileName,
        fileAttribute,
        shareState,
        type
      } = fileItem;
      console.log('=======fileItem=============================');
      console.log(fileItem);
      console.log('====================================');
      const { chunkSize, uploadFileList } = this;
      if (!file) return;
      const end = fileStart + chunkSize > size ? size : fileStart + chunkSize;
      const formData = new FormData();
      // console.log('起始', fileItem, this.chunkSize, end, uploadFileList);
      formData.append('file', file.slice(fileStart, end)); //文件本身
      formData.append('fileSize', size + ''); //文件大小（单位：KB）
      formData.append('fileStart', fileStart + ''); //断点传续文件起始点
      formData.append('endFlag', end === size ? '2' : '1'); //文件上传结束标识 1未完成 2完成
      formData.append('useFileName', useFileName); //文件名
      // formData.append('fixedId', uuid); //文件名
      fileName && formData.append('fileName', fileName); //文件名
      filePath && formData.append('filePath', filePath); //文件名
      formData.append('fileType', fileType); //文件类型（img:图片 ，officeFile：办公文件 3、video：视频、audio:音频 ，other：其他）
      //@ts-ignore
      fileBelongId && formData.append('fileBelongId', fileBelongId); //文件分类id
      //@ts-ignore
      type && formData.append('type', type); //文件分类id
      //@ts-ignore
      shareState && formData.append('shareState', shareState); //文件分类id
      //@ts-ignore
      fileAttribute && formData.append('fileAttribute', fileAttribute); //文档分类 1、个人文档 2、公共文档 3、知识库
      //@ts-ignore
      formData.append('locationMenu', 1); //文件展示定位目录
      //@ts-ignore
      formData.append('fileStorageLocal', 2); //文件展现定位
      //@ts-ignore
      // fileName && formData.append('fileName', fileName); //文件名
      formData.append('userId', userId); //对象id
      //@ts-ignore
      remark && formData.append('remark', remark); //	备注（文件类型为视频时，保存的视频时长）
      //@ts-ignore
      // formData.append('appCodes', [3]); //	备注（文件类型为视频时，保存的视频时长）
      sleepFor(300);
      try {
        let res = null;
        console.log('type', type);

        !type
          ? (res = await postFileUpload(formData))
          : (res = await PostFile(formData));
        const findIndex = uploadFileList.findIndex(
          (item) => item.uuid === uuid
        );
        // if (findIndex === -1) return arcoFun && arcoFun.onError('文件丢失'); //arco文件上传的回调
        this.uploadFileList[findIndex].fileName = res?.fileName;
        this.uploadFileList[findIndex].fileStart = end;
        this.uploadFileList[findIndex].id = res.id;
        // arcoFun && arcoFun.onProgress(Number((end / size).toFixed(4))); //arco文件上传的回调
        if (end >= size) {
          // const noticeStore = useNoticeStore();
          // const userInfo = JSON.parse(localStorage.getItem('userInfo') as any);
          if (!type) {
            uploadFileList[findIndex].state = 'success';
            delByUploadFileDb(uuid);
            this.successNum++;
            console.log('上传完成', uploadFileList[findIndex]);
          } else {
            if (arcoFun === 'aa') {
              this.uploadModelList = {
                ...res,
                useFileName,
                uuid: this.uploadFileList[findIndex].uuid
              };
            } else {
              arcoFun.onSuccess({
                ...res,
                useFileName,
                uuid: this.uploadFileList[findIndex].uuid
              });
            }
          }
        } else if (uploadFileList[findIndex].state === 'run') {
          type
            ? this.runUpload(uploadFileList[findIndex], arcoFun)
            : this.runUpload(uploadFileList[findIndex]);
        }
      } catch (err) {
        console.log('进入进入进入', err);
        const findIndex = uploadFileList.findIndex(
          (item) => item.uuid === uuid
        );
        // arcoFun && arcoFun.onError('上传失败'); //arco文件上传的回调
        if (findIndex === -1) return;
        this.uploadFileList[findIndex].state = 'error';
      }
    },
    //获取上传速率
    async getUploadBreakChunk() {
      try {
        // const { rate } = await GetUploadBreakChunk();
        this.chunkSize = 10 * 1024 * 1024;
        return Promise.resolve();
      } catch (err) {
        this.chunkSize = 1024 * 1024;
        return Promise.reject();
      }
    }
  }
});

const getFileType = (fileName: string): string => {
  const fileTypeList = [
    imgTypeList,
    officeTypeList,
    vidoeTypeList,
    audioTypeList
  ];
  const typeName = fileName.slice(fileName.lastIndexOf('.'));
  const mapIndex = fileTypeList.findIndex((typeMap) => {
    return typeMap.includes(typeName);
  });
  if (mapIndex === -1) return 'other';
  const map = ['img', 'officeFile', 'video', 'audio'];
  return map[mapIndex];
};

//等待函数
const sleepFor = (sleepDuration: number) => {
  const now = new Date().getTime();
  while (new Date().getTime() < now + sleepDuration) {
    //
  }
};

export default useStore;
