/* 储存人事模块-员工花名册里面的相关子路由（不显示在侧边导航栏的路由） */

const humanRosterSubRoutes: any = [
  /* 员工花名册新增员工 */
  {
    path: 'humanRosterAdd',
    name: 'HumanRosterAdd',
    component: () =>
      import(
        '@/views/Management/Human/HumanRoster/Components/AddHuman/index.vue'
      ),
    meta: {
      locale: '员工花名册新增员工',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'ditTemplate',
    name: 'EditTemplate',
    component: () =>
      import(
        '@/views/Management/Human/ChangeManagement/InductionManagement/EditTemplate/index.vue'
      ),
    meta: {
      locale: '编辑员工',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'formalities',
    name: 'Formalities',
    component: () =>
      import(
        '@/views/Management/Human/ChangeManagement/InductionManagement/Formalities/index.vue'
      ),
    meta: {
      locale: '配置入职手续',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'EditEntryScheme',
    name: 'EditEntryScheme',
    component: () =>
      import(
        '@/views/Management/Human/ChangeManagement/InductionManagement/EntryScheme/index.vue'
      ),
    meta: {
      locale: '配置入职方案',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'humanRosterDetail',
    name: 'HumanRosterDetail',
    component: () =>
      import('@/views/Management/Human/HumanRoster/FileDetail/index.vue'),
    meta: {
      locale: '员工档案详情',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'initiateApproval',
    name: 'InitiateApproval',
    component: () =>
      import(
        '@/views/Management/Human/ChangeManagement/InductionManagement/EntryManagement/InitiateApproval/index.vue'
      ),
    meta: {
      locale: '发起审核',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'leaveFormalities',
    name: 'LeaveFormalities',
    component: () =>
      import(
        '@/views/Management/Human/ChangeManagement/LeaveManagement/WaitLeave/Components/Formalities/index.vue'
      ),
    meta: {
      locale: '配置离职手续',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'roster/import',
    name: 'ImportRoster',
    component: () => import('@/views/Management/Human/ImportRoster/index.vue'),
    meta: {
      locale: '导入花名册',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'roster/materials',
    name: 'RossterMaterias',
    component: () =>
      import(
        '@/views/Management/Human/HumanRoster/FileDetail/Masteria/index.vue'
      ),
    meta: {
      locale: '入职材料',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  }
];

export default humanRosterSubRoutes;
