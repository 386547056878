import { createPinia } from 'pinia';
import useAppStore from './modules/base/app/';
import useUserStore from './modules/base/user';
import useTabBarStore from './modules/base/tabBar';
import useSetupFormStore from './modules/management/setupForm';
import useAssessmentToolStore from './modules/management/assessmentTool';
// import useOrderStore from './modules/order-management';
// import useBuyStore from './modules/buy-management';
import useMenusStore from './modules/management/menus';
import useGlobalParametersStore from './modules/globalParameters';
import useClientMenusStore from './modules/client/menus';
import useTenantStore from './modules/client/tenant/index';
import useContractStore from './modules/management/contract/index';
import useExamineTabsStore from './modules/base/examineTabs';
import useClientContactsStore from './modules/client/contacts/index';
import useUploadFileStore from './modules/uploadFile/index';
import usePermissionStore from './modules/management/permission';
import useNoticeStore from './modules/notice';

import useFlowStore from './modules/flow/index';

import useCustomCalendarStore from './modules/customCalendar/index'


const pinia = createPinia();

export {
  useAppStore,
  useUserStore,
  useTabBarStore,
  useMenusStore,
  useGlobalParametersStore,
  useSetupFormStore,
  useAssessmentToolStore,
  useClientContactsStore,
  useContractStore,
  usePermissionStore,
  // 员工端的stote
  useClientMenusStore,
  // useOrderStore,
  // useBuyStore,
  useFlowStore,
  useTenantStore,
  useExamineTabsStore,
  useUploadFileStore,
  useNoticeStore,
  useCustomCalendarStore
};
// export { useAppStore, useUserStore, useTabBarStore, };
export default pinia;
