/* 审批相关路由 */
import { DEFAULT_LAYOUT } from '../base';
import { AppRouteRecordRaw } from '../types';

const Approval: AppRouteRecordRaw = {
  path: '/Setting',
  name: 'Setting',
  component: DEFAULT_LAYOUT,
  meta: {
    locale: '设置 ',
    requiresAuth: true,
    icon: 'icon-home',
    order: 11,
    hideInMenu: false,
    key: '11'
  },
  redirect: {
    name: 'SecuritySetting'
  },
  children: [
    {
      path: 'enterpriseAccount',
      name: 'EnterpriseAccount',
      meta: {
        locale: '企业账号',
        requiresAuth: true,
        icon: 'icon-zuzhijigouguanli',
        roles: ['admin'],
        hideInMenu: false
      },
      children: [
        {
          path: 'information',
          name: 'Information',
          component: () => import('@/views/Enterprise/test/index.vue'),
          meta: {
            locale: '企业信息',
            requiresAuth: true,
            roles: ['admin'],
            hideInMenu: false
          }
        },
        // {
        //   path: 'rightsManagement',
        //   name: 'RightsManagement',
        //   component: () => import('@/views/Enterprise/test/index.vue'),
        //   meta: {
        //     locale: '权限管理',
        //     requiresAuth: true,
        //     roles: ['*'],
        //     hideInMenu: false
        //   }
        // },
        {
          path: 'roleHandling',
          name: 'RoleHandling',
          component: () =>
            import('@/views/Management/Home/RoleManagement/index.vue'),

          meta: {
            locale: '角色管理',
            requiresAuth: true,
            roles: ['*'],
            hideInMenu: false
          }
        },
        {
          path: 'enterprise/permissionAccount',
          name: 'EnterprisePermissionAccount',
          component: () => import('@/views/Enterprise/Account/index.vue'),

          meta: {
            locale: '账号管理',
            requiresAuth: true,
            roles: ['*'],
            hideInMenu: false
          }
        },
        {
          path: 'enterprise/account/handover',
          name: 'EnterpriseAccountHandover',
          component: () =>
            import('@/views/Enterprise/Account/Handover/index.vue'),

          meta: {
            locale: '权限移交',
            requiresAuth: true,
            roles: ['*'],
            hideInMenu: true
          }
        },
        {
          path: 'storage',
          name: 'Storage',
          component: () => import('@/views/Enterprise/test/index.vue'),

          meta: {
            locale: '存储空间',
            requiresAuth: true,
            roles: ['*'],
            hideInMenu: false
          }
        },
        {
          path: 'securitySetting',
          name: 'SecuritySetting',
          component: () =>
            import('@/views/Enterprise/Enterprise/SecuritySetting/index.vue'),

          meta: {
            locale: '安全设置',
            requiresAuth: true,
            roles: ['*'],
            hideInMenu: false
          }
        }
      ]
    },
    {
      path: 'enterpriseSettings',
      name: 'EnterpriseSettings',
      meta: {
        locale: '企业设置',
        requiresAuth: true,
        icon: 'icon-zuzhijigouguanli',
        roles: ['admin'],
        hideInMenu: false
      },
      children: [
        {
          path: 'pending',
          name: 'Pending',
          component: () => import('@/views/Enterprise/LoginTheme/index.vue'),

          meta: {
            locale: '登录主题',
            requiresAuth: true,
            roles: ['admin'],
            hideInMenu: false
          }
        }
      ]
    },
    {
      path: 'inviteToJoin',
      name: 'InviteToJoin',
      meta: {
        locale: '邀请入职',
        requiresAuth: true,
        icon: 'icon-zuzhijigouguanli',
        roles: ['admin'],
        hideInMenu: false
      },
      children: [
        {
          path: 'inviteToJoinMain',
          name: 'inviteToJoinMain',
          component: () => import('@/views/Enterprise/test/index.vue'),

          meta: {
            locale: '邀请入职',
            requiresAuth: true,
            roles: ['admin'],
            hideInMenu: false
          }
        }
      ]
    }
  ]
};

export default Approval;
