/* 储存人事模块-人员管理里面的相关子路由（不显示在侧边导航栏的路由） */

const humanChangeManagementSubRoutes: any = [
  {
    path: 'entryApplicationSetting',
    name: 'EntryApplicationSetting',
    component: () =>
      import(
        '@/views/Management/Human/ChangeManagement/InductionManagement/EntryApplicationSetting/index.vue'
      ),
    meta: {
      locale: '入职申请设置',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'entryApplicationSettingAdd',
    name: 'EntryApplicationSettingAdd',
    component: () =>
      import(
        '@/views/Management/Human/ChangeManagement/InductionManagement/EntryApplicationSetting/AddSetting/index.vue'
      ),
    meta: {
      locale: '新增入职申请',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  // {
  //   path: 'entryApplicationInviteMobile',
  //   name: 'EntryApplicationInviteMobile',
  //   component: () =>
  //     import(
  //       '@/views/Management/Human/ChangeManagement/InductionManagement/EntryApplicationSetting/InviteMobile/index.vue'
  //     ),
  //   meta: {
  //     locale: '入职申请',
  //     requiresAuth: false,
  //     // roles: ['*'],
  //     // hideInMenu: true
  //   }
  // },
  {
    path: 'entryNotice',
    name: 'EntryNotice',
    component: () =>
      import(
        '@/views/Management/Human/ChangeManagement/InductionManagement/EntryNotice/index.vue'
      ),
    meta: {
      locale: '入职通知',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'sendIntershipEvaluation',
    name: 'SendIntershipEvaluation',
    component: () =>
      import(
        '@/views/Management/Human/ChangeManagement/InternshipManagement/Evaluation/Components/IntershipEvaluation/index.vue'
      ),
    meta: {
      locale: '发起实习评价',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'internshipEvaluationDetail',
    name: 'InternshipEvaluationDetail',
    component: () =>
      import('@/components/InternshipEvaluationDetail/index.vue'),
    meta: {
      locale: '实习评价详情',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'confirmDetail',
    name: 'ConfirmDetail',
    component: () =>
      import(
        '@/views/Management/Human/ChangeManagement/ConfirmationManagement/Components/Detail/index.vue'
      ),
    meta: {
      locale: '转正详情',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'confirmApproval',
    name: 'ConfirmApproval',
    component: () =>
      import(
        '@/views/Management/Human/ChangeManagement/ConfirmationManagement/Components/Approval/index.vue'
      ),
    meta: {
      locale: '转正审批发起',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'confirmProcedure',
    name: 'ConfirmProcedure',
    component: () =>
      import(
        '@/views/Management/Human/ChangeManagement/ConfirmationManagement/Components/Procedure/index.vue'
      ),
    meta: {
      locale: '转正手续办理',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'leaveHandover',
    name: 'LeaveHandover',
    component: () =>
      import(
        '@/views/Management/Human/ChangeManagement/LeaveManagement/WaitLeave/Components/LeaveHandover/index.vue'
      ),
    meta: {
      locale: '发起离职工作交接',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'leaveInterView',
    name: 'LeaveInterView',
    component: () =>
      import(
        '@/views/Management/Human/ChangeManagement/LeaveManagement/WaitLeave/Components/LeaveInterView/index.vue'
      ),
    meta: {
      locale: '发起离职面谈',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'handleTransaction',
    name: 'HandleTransaction',
    component: () =>
      import(
        '@/views/Management/Human/ChangeManagement/TransactionManagement/WaitTransaction/Components/HandleTransaction/index.vue'
      ),
    meta: {
      locale: '办理人事异动',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'transactionRecordDetail/:id',
    name: 'TransactionRecordDetail',
    component: () =>
      import(
        '@/views/Management/Human/ChangeManagement/TransactionManagement/TransactionRecord/Components/HandleTransaction/index.vue'
      ),
    meta: {
      locale: '人事异动详情',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'postLeaveApply',
    name: 'PostLeaveApply',
    component: () =>
      import(
        '@/views/Management/Human/ChangeManagement/LeaveManagement/LeaveApply/Components/PostLeaveApply/index.vue'
      ),
    meta: {
      locale: '发起离职申请',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'entryFormalitiesDetail/:id',
    name: 'EntryFormalitiesDetail',
    component: () =>
      import(
        '@/views/Management/Human/ChangeManagement/InductionManagement/EntryManagement/Formalities/index.vue'
      ),
    meta: {
      locale: '入职手续详情',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'leaveFormalitiesDetail/:id',
    name: 'LeaveFormalitiesDetail',
    component: () =>
      import(
        '@/views/Management/Human/ChangeManagement/LeaveManagement/WaitLeave/Formalities/index.vue'
      ),
    meta: {
      locale: '离职手续详情',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  }
];

export default humanChangeManagementSubRoutes;
