/* 储存薪酬模块-基础信息里面的相关子路由（不显示在侧边导航栏的路由） */

const salaryBaseSettingIndexSubRoutes: any = [
  {
    path: 'salaryGroup',
    name: 'SalaryGroup',
    component: () =>
      import(
        '@/views/Management/Salary/BaseSetting/SalaryPlan/SalaryGroup/index.vue'
      ),
    meta: {
      locale: '发薪日设置',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'bonusRules',
    name: 'BonusRules',
    component: () =>
      import(
        '@/views/Management/Salary/BaseSetting/SalaryPlan/BonusRules/index.vue'
      ),
    meta: {
      locale: '奖金规则',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'bonusRulesDetails',
    name: 'BonusRulesDetails',
    component: () =>
      import(
        '@/views/Management/Salary/BaseSetting/SalaryPlan/BonusRules/Details/index.vue'
      ),
    meta: {
      locale: '奖金规则设置',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'subsidySetting',
    name: 'SubsidySetting',
    component: () =>
      import('@/views/Management/Salary/BaseSetting/SubsidySetting/index.vue'),
    meta: {
      locale: '津补贴设置',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'attendanceSalarySetting',
    name: 'AttendanceSalarySetting',
    component: () =>
      import(
        '@/views/Management/Salary/BaseSetting/AttendanceSalarySetting/index.vue'
      ),
    meta: {
      locale: '考勤计薪设置',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'standardSalarySetting',
    name: 'StandardSalarySetting',
    component: () =>
      import(
        '@/views/Management/Salary/BaseSetting/StandardSalarySetting/index.vue'
      ),
    meta: {
      locale: '标准薪资项目',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'salaryTemplate',
    name: 'SalaryTemplate',
    component: () =>
      import(
        '@/views/Management/Salary/BaseSetting/SalarySetting/SalaryTemplate/index.vue'
      ),
    meta: {
      locale: '工资表模板',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'addSalaryTemplate',
    name: 'AddSalaryTemplate',
    component: () =>
      import(
        '@/views/Management/Salary/BaseSetting/SalarySetting/SalaryTemplate/AddSalaryTemplate/index.vue'
      ),
    meta: {
      locale: '新增工资表模板',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'addWageAddition',
    name: 'AddWageAddition',
    component: () =>
      import(
        '@/views/Management/Salary/BaseSetting/SalarySetting/SalaryTemplate/AddSalaryTemplate/WageAddition/index.vue'
      ),
    meta: {
      locale: '新增工资项',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'payStructure',
    name: 'PayStructure',
    component: () =>
      import(
        '@/views/Management/Salary/BaseSetting/SalarySetting/SalaryTemplate/AddSalaryTemplate/PayStructure/index.vue'
      ),
    meta: {
      locale: '设置薪酬结构',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'addStatisticalSetting',
    name: 'AddStatisticalSetting',
    component: () =>
      import(
        '@/views/Management/Salary/BaseSetting/SalarySetting/SalaryTemplate/AddSalaryTemplate/AddStatisticalSetting/index.vue'
      ),
    meta: {
      locale: '新增统计项',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },

  {
    path: 'minSalary',
    name: 'MinSalary',
    component: () =>
      import(
        '@/views/Management/Salary/BaseSetting/SalarySetting/MinSalary/index.vue'
      ),
    meta: {
      locale: '最低工资',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'enterpriseSetting',
    name: 'EnterpriseSetting',
    component: () =>
      import(
        '@/views/Management/Salary/BaseSetting/SalarySetting/EnterpriseSetting/index.vue'
      ),
    meta: {
      locale: '企业设置',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'checkRules',
    name: 'CheckRules',
    component: () =>
      import(
        '@/views/Management/Salary/BaseSetting/SalaryRules/CheckRules/index.vue'
      ),
    meta: {
      locale: '考勤计薪规则',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'gradeSalarySetting',
    name: 'GradeSalarySetting',
    component: () =>
      import(
        '@/views/Management/Salary/BaseSetting/GradeSalarySetting/index.vue'
      ),
    meta: {
      locale: '职等工资规则',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'annualSalaryRules',
    name: 'AnnualSalaryRules',
    component: () =>
      import(
        '@/views/Management/Salary/BaseSetting/AnnualSalaryRules/index.vue'
      ),
    meta: {
      locale: '年薪规则',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'annualSalaryRulesDetails',
    name: 'AnnualSalaryRulesDetails',
    component: () =>
      import(
        '@/views/Management/Salary/BaseSetting/AnnualSalaryRules/Details/index.vue'
      ),
    meta: {
      locale: '年薪规则详情',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'taxRules',
    name: 'TaxRules',
    component: () =>
      import(
        '@/views/Management/Salary/BaseSetting/SalaryRules/TaxRules/index.vue'
      ),
    meta: {
      locale: '计税规则',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'allowanceRules',
    name: 'AllowanceRules',
    component: () =>
      import(
        '@/views/Management/Salary/BaseSetting/SalaryRules/AllowanceRules/index.vue'
      ),
    meta: {
      locale: '津贴补规则',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'performanceRules',
    name: 'PerformanceRules',
    component: () =>
      import(
        '@/views/Management/Salary/BaseSetting/SalaryRules/PerformanceRules/index.vue'
      ),
    meta: {
      locale: '绩效规则',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'pieceRules',
    name: 'PieceRules',
    component: () =>
      import(
        '@/views/Management/Salary/BaseSetting/SalaryRules/PieceRules/index.vue'
      ),
    meta: {
      locale: '计件工资',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'timeRules',
    name: 'TimeRules',
    component: () =>
      import(
        '@/views/Management/Salary/BaseSetting/SalaryRules/TimeRules/index.vue'
      ),
    meta: {
      locale: '计时工资',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  }
];

export default salaryBaseSettingIndexSubRoutes;
