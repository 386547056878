export const WHITE_LIST = [
  { name: 'notFound', children: [] },
  { name: 'login', children: [] },
  { name: 'EntryApplicationInviteMobile', children:[] }
];

export const NOT_FOUND = {
  name: 'notFound'
};

export const REDIRECT_ROUTE_NAME = 'Redirect';

// 管理端默认路由名字
export const DEFAULT_MANAGEMENT_ROUTE_NAME = 'WorkPlace';

// 员工端默认路由名字
export const DEFAULT_CLIENT_ROUTE_NAME = 'C_ClientWorkPlace';

// 管理端默认路由
export const DEFAULT_MANAGEMENT_ROUTE = {
  title: '管理员工作台',
  name: DEFAULT_MANAGEMENT_ROUTE_NAME,
  fullPath: '/home/workplace'
};

// 员工端默认路由
export const DEFAULT_CLIENT_ROUTE = {
  title: '员工工作台',
  name: DEFAULT_CLIENT_ROUTE_NAME,
  fullPath: '/client/clientWorkPlace'
};
