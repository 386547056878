/* 储存人事模块-基础信息里面的相关子路由（不显示在侧边导航栏的路由） */

const humanBaseSettingSubRoutes: any = [
  /* 人才标签设置 */
  {
    path: 'humanLabelSetting',
    name: 'HumanLabelSetting',
    component: () =>
      import(
        '@/views/Management/Human/BaseSetting/HumanSetting/LabelSetting/index.vue'
      ),
    meta: {
      locale: '人才标签设置',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'humanTemplateSetting',
    name: 'HumanTemplateSetting',
    component: () =>
      import(
        '@/views/Management/Human/BaseSetting/HumanSetting/HumanTemplateSetting/index.vue'
      ),
    meta: {
      locale: '人员信息模板',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'humanChangeSetting',
    name: 'HumanChangeSetting',
    component: () =>
      import(
        '@/views/Management/Human/BaseSetting/HumanSetting/ChangeSetting/index.vue'
      ),
    meta: {
      locale: '异动设置',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },

  /* 入职方案模板设置 */
  {
    path: 'Entryscheme',
    name: 'Entryscheme',
    component: () =>
      import(
        '@/views/Management/Human/BaseSetting/TransactionSetting/EntryScheme/index.vue'
      ),
    meta: {
      locale: '入职方案模板设置',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  /* 入职通知模板设置 */
  {
    path: 'EntryProof',
    name: 'EntryProof',
    component: () =>
      import(
        '@/views/Management/Human/BaseSetting/TransactionSetting/EntryProof/index.vue'
      ),
    meta: {
      locale: '入职通知模板设置',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  /* 入职手续模板设置 */
  {
    path: 'EntryFormalities',
    name: 'EntryFormalities',
    component: () =>
      import(
        '@/views/Management/Human/BaseSetting/TransactionSetting/EntryFormalities/index.vue'
      ),
    meta: {
      locale: '入职手续模板设置',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  /* 工号规则设置*/
  {
    path: 'JobNumber',
    name: 'JobNumber',
    component: () =>
      import(
        '@/views/Management/Human/BaseSetting/TransactionSetting/JobNumber/index.vue'
      ),
    meta: {
      locale: '工号规则设置',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  /* 离职类型及原因设置*/
  {
    path: 'DimissionType',
    name: 'DimissionType',
    component: () =>
      import(
        '@/views/Management/Human/BaseSetting/DimissionSetting/DimissionType/index.vue'
      ),
    meta: {
      locale: '离职类型及原因设置',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  /* 离职面谈记录表*/
  {
    path: 'DimissionInterview',
    name: 'DimissionInterview',
    component: () =>
      import(
        '@/views/Management/Human/BaseSetting/DimissionSetting/DimissionInterview/index.vue'
      ),
    meta: {
      locale: '离职面谈记录表',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  /* 离职问题库*/
  {
    path: 'DimissionProblem',
    name: 'DimissionProblem',
    component: () =>
      import(
        '@/views/Management/Human/BaseSetting/DimissionSetting/DimissionProblem/index.vue'
      ),
    meta: {
      locale: '离职问题库',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  /* 离职提问设置*/
  {
    path: 'DimisssionQuiz',
    name: 'DimisssionQuiz',
    component: () =>
      import(
        '@/views/Management/Human/BaseSetting/DimissionSetting/DimisssionQuiz/index.vue'
      ),
    meta: {
      locale: '离职工作交接',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'dimisssionQuizTemplate',
    name: 'DimisssionQuizTemplate',
    component: () =>
      import(
        '@/views/Management/Human/BaseSetting/DimissionSetting/DimisssionQuiz/Components/Template/index.vue'
      ),
    meta: {
      locale: '离职工作交接表模板',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  /* 离职手续模板设置*/
  {
    path: 'DimissionFormalities',
    name: 'DimissionFormalities',
    component: () =>
      import(
        '@/views/Management/Human/BaseSetting/DimissionSetting/DimissionFormalities/index.vue'
      ),
    meta: {
      locale: '离职手续模板',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'probationAssessment',
    name: 'ProbationAssessment',
    component: () =>
      import(
        '@/views/Management/Human/BaseSetting/ProbationSetting/ProbationAssessment/index.vue'
      ),
    meta: {
      locale: '试用期考核方案配置',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },

  {
    path: 'InternshipTemplate',
    name: 'InternshipTemplate',
    component: () =>
      import(
        '@/views/Management/Human/BaseSetting/IntershipSetting/InternshipTemplate/index.vue'
      ),
    meta: {
      locale: '实习期设置',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'internshipEvaluationTemplate',
    name: 'InternshipEvaluationTemplate',
    component: () =>
      import(
        '@/views/Management/Human/BaseSetting/IntershipSetting/IntershipEvaluation/index.vue'
      ),
    meta: {
      locale: '实习评价模板设置',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'internshipIndex',
    name: 'InternshipIndex',
    component: () =>
      import(
        '@/views/Management/Human/BaseSetting/IntershipSetting/InternshipIndex/index.vue'
      ),
    meta: {
      locale: '实习指标库',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'intershipAgreement',
    name: 'IntershipAgreement',
    component: () =>
      import(
        '@/views/Management/Human/BaseSetting/IntershipSetting/IntershipAgreement/index.vue'
      ),
    meta: {
      locale: '实习协议模板设置',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'InternshipProofMode',
    name: 'InternshipProofMode',
    component: () =>
      import(
        '@/views/Management/Human/BaseSetting/CertificateSetting/InternshipProofMode/index.vue'
      ),
    meta: {
      locale: '实习证明开具方式设置',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  // {
  //   path: 'InternshipTemplateDetail',
  //   name: 'InternshipTemplateDetail',
  //   component: () =>
  //     import(
  //       '@/views/Management/Human/BaseSetting/IntershipSetting/InternshipProofMode/InternshipTemplateDetail/index.vue'
  //     ),
  //   meta: {
  //     locale: '实习证明模板',
  //     requiresAuth: true,
  //     roles: ['*'],
  //     hideInMenu: true
  //   }
  // },
  {
    path: 'internshipProofTemplateDetail',
    name: 'InternshipProofTemplateDetail',
    component: () =>
      import(
        '@/views/Management/Human/BaseSetting/CertificateSetting/InternshipProofMode/InternshipTemplateDetail/index.vue'
      ),
    meta: {
      locale: '实习证明开具证明模板',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'LeaveProofMode',
    name: 'LeaveProofMode',
    component: () =>
      import(
        '@/views/Management/Human/BaseSetting/CertificateSetting/LeaveProofMode/index.vue'
      ),
    meta: {
      locale: '离职证明开具设置',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'leaveProofTemplateDetail',
    name: 'LeaveProofTemplateDetail',
    component: () =>
      import(
        '@/views/Management/Human/BaseSetting/CertificateSetting/LeaveProofMode/LeaveTemplateDetail/index.vue'
      ),
    meta: {
      locale: '离职证明开具证明模板',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'WorkProofMode',
    name: 'WorkProofMode',
    component: () =>
      import(
        '@/views/Management/Human/BaseSetting/CertificateSetting/WorkProofMode/index.vue'
      ),
    meta: {
      locale: '工作证明开具方式设置',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'WorkProofTemplateDetail',
    name: 'WorkProofTemplateDetail',
    component: () =>
      import(
        '@/views/Management/Human/BaseSetting/CertificateSetting/WorkProofMode/WorkTemplateDetail/index.vue'
      ),
    meta: {
      locale: '离工作证明开具证明模板',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'IncomeProofMode',
    name: 'IncomeProofMode',
    component: () =>
      import(
        '@/views/Management/Human/BaseSetting/CertificateSetting/IncomeProofMode/index.vue'
      ),
    meta: {
      locale: '收入证明开具方式设置',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'IncomeProofTemplateDetail',
    name: 'IncomeProofTemplateDetail',
    component: () =>
      import(
        '@/views/Management/Human/BaseSetting/CertificateSetting/IncomeProofMode/IncomeTemplateDetail/index.vue'
      ),
    meta: {
      locale: '离工作证明开具证明模板',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  // {
  //   path: 'InternshipTemplateAuditDetail',
  //   name: 'InternshipTemplateAuditDetail',
  //   component: () =>
  //     import(
  //       '@views/Management/Human/BaseSetting/IntershipSetting/InternshipProofMode/InternshipTemplateDetail/index.vue'
  //     ),
  //   meta: {
  //     locale: '编辑实习证明申请模板',
  //     requiresAuth: true,
  //     roles: ['*'],
  //     hideInMenu: true
  //   }
  // },
  {
    path: 'confirmExamineSetting',
    name: 'ConfirmExamineSetting',
    component: () =>
      import(
        '@/views/Management/Human/BaseSetting/ConfirmSetting/ConfirmExamineSetting/index.vue'
      ),
    meta: {
      locale: '转正审批流程设置',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'interviewRecord',
    name: 'InterviewRecord',
    component: () =>
      import(
        '@/views/Management/Human/BaseSetting/ConfirmSetting/InterviewRecord/index.vue'
      ),
    meta: {
      locale: '面谈记录',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'performanceLevelSetting',
    name: 'PerformanceLevelSetting',
    component: () =>
      import(
        '@/views/Management/Human/BaseSetting/ConfirmSetting/PerformanceLevelSetting/index.vue'
      ),
    meta: {
      locale: '绩效等级设置',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  /* 转正提醒设置 */
  {
    path: 'confirmRemindSetting',
    name: 'ConfirmRemindSetting',
    component: () =>
      import(
        '@/views/Management/Human/BaseSetting/InformationRemind/ConfirmRemind/index.vue'
      ),
    meta: {
      locale: '转正提醒设置',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  // {
  //   path: 'contractRemindSetting',
  //   name: 'ContractRemindSetting',
  //   component: () =>
  //     import(
  //       '@/views/Management/Human/BaseSetting/InformationRemind/ContractRemind/index.vue'
  //     ),
  //   meta: {
  //     locale: '合同签订提醒设置',
  //     requiresAuth: true,
  //     roles: ['*'],
  //     hideInMenu: true
  //   }
  // },
  {
    path: 'attendenceRemindSetting',
    name: 'AttendenceRemindSetting',
    component: () =>
      import(
        '@/views/Management/Human/BaseSetting/InformationRemind/AttendenceRemind/index.vue'
      ),
    meta: {
      locale: '考勤异常提醒设置',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'noMatchRemindSetting',
    name: 'NoMatchRemindSetting',
    component: () =>
      import(
        '@/views/Management/Human/BaseSetting/InformationRemind/NoMatchRemind/index.vue'
      ),
    meta: {
      locale: '考勤与薪资不匹配提醒设置',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'birthdayRemindSetting',
    name: 'BirthdayRemindSetting',
    component: () =>
      import(
        '@/views/Management/Human/BaseSetting/InformationRemind/BirthdayRemind/index.vue'
      ),
    meta: {
      locale: '生日提醒设置',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  }
];

export default humanBaseSettingSubRoutes;
