/*
 * @Author: 陈俊升 761723995@qq.com
 * @Date: 2024-02-23 09:10:58
 * @LastEditors: 陈俊升 761723995@qq.com
 * @LastEditTime: 2024-03-19 16:08:14
 * @FilePath: /hr_new_vue3/src/router/routes/management/administration.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/* 存放行政相关路由 */

import { DEFAULT_LAYOUT } from '../base';
// import DEFAULT_LAYOUT from '@/layout/default-layout.vue'
import { AppRouteRecordRaw } from '../types';
import administrationSubRoutes from './administration/index';
import homeSub from './homeSub';

const ADMINISTRATION: AppRouteRecordRaw = {
  path: '/administration',
  name: 'Administration',
  component: DEFAULT_LAYOUT,
  meta: {
    locale: '行政',
    requiresAuth: true,
    icon: 'icon-home',
    order: 7,
    hideInMenu: false,
    key: '8',
    roles: ['admin']
  },
  redirect: {
    name: 'AdministrationBaseSetting'
  },
  children: [
    ...homeSub,
    {
      path: 'administrationBaseSetting',
      name: 'AdministrationBaseSetting',
      component: () =>
        import(
          '@/views/Management/Administration/AdministrationBaseSetting/index.vue'
        ),
      meta: {
        locale: '基础设置',
        requiresAuth: true,
        icon: 'icon-jichushezhi',
        roles: ['*'],
        hideInMenu: false
      }
    },
    {
      path: 'enterpriseAnnouncement',
      name: 'EnterpriseAnnouncement',
      component: () =>
        import(
          '@/views/Management/Administration/EnterpriseAnnouncement/index.vue'
        ),
      meta: {
        locale: '企业公告',
        requiresAuth: true,
        icon: 'icon-jichushezhi',
        roles: ['*'],
        hideInMenu: false
      }
    },
    {
      path: 'enterpriseSystem',
      name: 'EnterpriseSystem',
      component: () =>
        import('@/views/Management/Administration/EnterpriseSystem/index.vue'),
      meta: {
        locale: '企业制度',
        requiresAuth: true,
        icon: 'icon-jichushezhi',
        roles: ['*'],
        hideInMenu: false
      }
    },
    {
      path: 'activityManagement',
      name: 'ActivityManagement',
      component: () =>
        import(
          '@/views/Management/Administration/ActivityManagement/index.vue'
        ),
      meta: {
        locale: '活动管理',
        requiresAuth: true,
        icon: 'icon-jichushezhi',
        roles: ['*'],
        hideInMenu: false
      }
    },
    ...administrationSubRoutes
  ]
};

export default ADMINISTRATION;
