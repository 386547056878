//上传文件用于缓存文件数据库
import {
  openDb,
  addDbData,
  getDbDataByKey,
  delDbDataByKey
} from './dbFunction';
// import { any } from '~/apis/resource/type';

let db: IDBDatabase | undefined; //数据库实例

//打开数据库
export async function openUploadFileDb() {
  //数据库更新初始化
  const updataDbCallBack = (db: IDBDatabase) => {
    const objectStore = db.createObjectStore('uploadFileList', {
      keyPath: 'uuid'
    });
    objectStore.createIndex('file', 'file', { unique: false });
  };

  db = await openDb('uploadFile', 5, updataDbCallBack);

  return Promise.resolve();
}

//向uploadFile新增一条数据
export async function addByUploadFileDb(data: any) {
  if (!db) await openUploadFileDb();
  try {
    const res = await addDbData(db as IDBDatabase, 'uploadFileList', data);
    console.log(res);
  } catch (err) {
    console.log(err);
  }
}

//根据uuid向uploadFile查找一条数据
export async function getByUploadFileDb(uuid: string) {
  if (!db) await openUploadFileDb();
  const data = await getDbDataByKey<any>(
    db as IDBDatabase,
    'uploadFileList',
    uuid
  );
  return Promise.resolve(data);
}

//根据uuid删除uploadFile一条数据
export async function delByUploadFileDb(uuid: string) {
  if (!db) await openUploadFileDb();
  const data = await delDbDataByKey(db as IDBDatabase, 'uploadFileList', uuid);
  return Promise.resolve(data);
}

//关闭数据库
export async function closeUploadFileDb() {
  if (!db) return;
  db.close();
  db = undefined;
}
