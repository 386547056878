/* 储存组织模块-基础信息里面的相关子路由（不显示在侧边导航栏的路由） */

const performanceBaseSettingIndexSubRoutes: any = [
  /* 关键业绩指标库 */
  {
    path: 'kpiIndex',
    name: 'KpiIndex',
    component: () =>
      import(
        '@/views/Management/Performance/BaseSetting/Index/KpiIndex/index.vue'
      ),
    meta: {
      locale: '关键业绩指标库',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  /* 能力素质指标库 */
  {
    path: 'bpiIndex',
    name: 'BpiIndex',
    component: () =>
      import(
        '@/views/Management/Performance/BaseSetting/Index/BpiIndex/index.vue'
      ),
    meta: {
      locale: '能力素质指标库',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  /* 工作计划指标库 */
  {
    path: 'ppiIndex',
    name: 'PpiIndex',
    component: () =>
      import(
        '@/views/Management/Performance/BaseSetting/Index/PpiIndex/index.vue'
      ),
    meta: {
      locale: '工作计划指标库',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  /* 目标与关键成果指标库 */
  {
    path: 'okrIndex',
    name: 'OkrIndex',
    component: () =>
      import(
        '@/views/Management/Performance/BaseSetting/Index/OkrIndex/index.vue'
      ),
    meta: {
      locale: '目标与关键成果指标库',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  /* 加减分项指标库 */
  {
    path: 'plusOrMinusIndex',
    name: 'PlusOrMinusIndex',
    component: () =>
      import(
        '@/views/Management/Performance/BaseSetting/Index/PlusOrMinusIndex/index.vue'
      ),
    meta: {
      locale: '加减分项指标库',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  /* 否决指标库 */
  {
    path: 'vetoIndex',
    name: 'VetoIndex',
    component: () =>
      import(
        '@/views/Management/Performance/BaseSetting/Index/VetoIndex/index.vue'
      ),
    meta: {
      locale: '否决指标库',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  /* 绩效考核模板 */
  {
    path: 'performanceTemplate',
    name: 'PerformanceTemplate',
    component: () =>
      import(
        '@/views/Management/Performance/BaseSetting/AssessmentTemplate/PerformanceTemplate/index.vue'
      ),
    meta: {
      locale: '绩效考核模板',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  /* 试用期考核模板 */
  {
    path: 'probationTemplate',
    name: 'ProbationTemplate',
    component: () =>
      import(
        '@/views/Management/Performance/BaseSetting/AssessmentTemplate/ProbationTemplate/index.vue'
      ),
    meta: {
      locale: '试用期考核模板',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  /* 晋升考核模板 */
  {
    path: 'promotionTemplate',
    name: 'PromotionTemplate',
    component: () =>
      import(
        '@/views/Management/Performance/BaseSetting/AssessmentTemplate/PromotionTemplate/index.vue'
      ),
    meta: {
      locale: '晋升考核模板',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  /* 任期考核模板 */
  {
    path: 'tenureTemplate',
    name: 'TenureTemplate',
    component: () =>
      import(
        '@/views/Management/Performance/BaseSetting/AssessmentTemplate/TenureTemplate/index.vue'
      ),
    meta: {
      locale: '任期考核模板',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  /* 合同续签考核模板 */
  // {
  //   path: 'contractTemplate',
  //   name: 'ContractTemplate',
  //   component: () =>
  //     import(
  //       '@/views/Management/Performance/BaseSetting/AssessmentTemplate/ContractTemplate/index.vue'
  //     ),
  //   meta: {
  //     locale: '合同续签考核模板',
  //     requiresAuth: true,
  //     roles: ['*'],
  //     hideInMenu: true
  //   }
  // },
  /* 其他考核模板 */
  {
    path: 'otherTemplate',
    name: 'OtherTemplate',
    component: () =>
      import(
        '@/views/Management/Performance/BaseSetting/AssessmentTemplate/OtherTemplate/index.vue'
      ),
    meta: {
      locale: '其他考核模板',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  }
];

export default performanceBaseSettingIndexSubRoutes;
