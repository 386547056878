// 信息通知
import { ajaxService } from '@/utils/request';

const env = import.meta.env;
const baseURL = env.VITE_APP_PLAT_URL;
const baseURL1 = env.VITE_APP_BASE_URL;

// 消息中心中查看用户待办事项
export function getScheduleCenter(params: any) {
  return ajaxService({
    url: '/hr/administration/backlog/date/center',
    method: 'get',
    baseURL: baseURL1,
    params
  });
}

// 查询用户的消息
export function getMessageCenter(params: any) {
  return ajaxService({
    url: '/lke_message/msg/api/message/page',
    method: 'get',
    baseURL,
    params
  });
}

// 查询单条消息并且阅读状态改为已读
export function getMsgApiMessag(params: any) {
  return ajaxService({
    url: '/lke_message/msg/api/message',
    method: 'get',
    baseURL,
    params
  });
}

// 删除该消息的用户接收者
export function delMsgApiMessag(params: any) {
  return ajaxService({
    url: '/lke_message/msg/api/message/receiver',
    method: 'delete',
    baseURL,
    params
  });
}

// 新增消息
export function postMsgApiMessag(data: any) {
  return ajaxService({
    url: '/lke_message/msg/api/message',
    method: 'post',
    baseURL,
    data
  });
}

// 新增消息
export function delMsgApiMessagIds(data: any) {
  return ajaxService({
    url: '/lke_message/msg/api/message/ids',
    method: 'delete',
    baseURL,
    data
  });
}
