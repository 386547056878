/* 我的日历api */

import { ajaxService } from '@/utils/request';

const env = import.meta.env;
// const baseURL = env.VITE_API_BASE_URL;
const baseURL = env.VITE_APP_BASE_URL;


/**
 * 新增我的日历日程
 */
export function PostMyScheDule(data:object) {
  return ajaxService({
    url: '/hr/administration/schedule',
    method: 'post',
    baseURL: baseURL,
    data
  },
  {
    mode: 'success',
    message: '新增成功！'
  });
}

/**
 * 修改我的日历日程
 */
export function PutMyScheDule(data:object) {
  return ajaxService({
    url: '/hr/administration/schedule',
    method: 'put',
    baseURL: baseURL,
    data
  },
  {
    mode: 'success',
    message: '修改成功！'
  });
}

/**
 * 获取我的日历日程
 */
export function GetMyScheDule(data:object) {
  return ajaxService({
    url: '/hr/administration/schedule/list',
    method: 'post',
    baseURL: baseURL,
    data
  });
}

/**
 * 删除我的日历日程
 */
export function DelMyScheDule(params:object) {
  return ajaxService({
    url: '/hr/administration/schedule',
    method: 'DELETE',
    baseURL: baseURL,
    params
  },
  {
    mode: 'success',
    message: '删除成功！'
  });
}
