/* 储存合同模块-基础信息里面的相关子路由（不显示在侧边导航栏的路由） */

const contractBaseSettingIndexSubRoutes: any = [
  {
    path: 'corporateSeal',
    name: 'CorporateSeal',
    component: () =>
      import('@/views/Management/Contract/BaseSetting/CorporateSeal/index.vue'),
    meta: {
      locale: '企业印章',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'corporateSealAdd',
    name: 'CorporateSealAdd',
    component: () =>
      import(
        '@/views/Management/Contract/BaseSetting/CorporateSeal/AddSeal.vue'
      ),
    meta: {
      locale: '创建印章',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'contractTemplate',
    name: 'ContractTemplate',
    component: () =>
      import(
        '@/views/Management/Contract/BaseSetting/ContractTemplate/index.vue'
      ),
    meta: {
      locale: '合同类型及模板',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'contractTemplateList',
    name: 'ContractTemplateList',
    component: () =>
      import(
        '@/views/Management/Contract/BaseSetting/ContractTemplate/Components/ContractTemplateList/index.vue'
      ),
    meta: {
      key: '$route.fullPath',
      locale: '合同模板列表',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'contractRemindSetting',
    name: 'ContractRemindSetting',
    component: () =>
      import(
        '@/views/Management/Contract/BaseSetting/ContractRemind/index.vue'
      ),
    meta: {
      locale: '合同预警提醒',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'contractTypeSetting',
    name: 'ContractTypeSetting',
    component: () =>
      import(
        '@/views/Management/Contract/BaseSetting/ContractSetting/index.vue'
      ),
    meta: {
      locale: '合同异动及原因设置',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'contractTemplateGroup',
    name: 'ContractTemplateGroup',
    component: () =>
      import(
        '@/views/Management/Contract/BaseSetting/ContractTemplate/Components/Group/index.vue'
      ),
    meta: {
      locale: '合同类型分组',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'contractBag',
    name: 'ContractBag',
    component: () =>
      import('@/views/Management/Contract/BaseSetting/ContractBag/index.vue'),
    meta: {
      locale: '合同套包',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'contractRenewal',
    name: 'ContractRenewal',
    component: () =>
      import(
        '@/views/Management/Contract/BaseSetting/ContractRenewal/index.vue'
      ),
    meta: {
      locale: '合同续签意向表设置',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'contractEducation',
    name: 'ContractEducation',
    component: () =>
      import(
        '@/views/Management/Contract/BaseSetting/ContractEducation/index.vue'
      ),
    meta: {
      locale: '合同续签评估设置',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  {
    path: 'contractCoding',
    name: 'ContractCoding',
    component: () =>
      import(
        '@/views/Management/Contract/BaseSetting/ContractCoding/index.vue'
      ),
    meta: {
      locale: '合同编码设置',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  }
];

export default contractBaseSettingIndexSubRoutes;
