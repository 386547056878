/* 审批相关路由 */

import { DEFAULT_LAYOUT } from '../base';
import { AppRouteRecordRaw } from '../types';
import homeSub from './homeSub';

const Approval: AppRouteRecordRaw = {
  path: '/workplace2',
  name: 'WorkPlace2',
  component: DEFAULT_LAYOUT,
  meta: {
    locale: '工作台',
    requiresAuth: true,
    icon: 'icon-home',
    roles: ['admin'],
    hideInMenu: false
  },
  redirect: {
    name: 'WorkPlace'
  },
  children: [
    ...homeSub,
    {
      path: '/center',
      name: 'Center',
      component: () => import('@/qiankun/index.vue'),
      meta: {
        locale: '申请中心',
        requiresAuth: true,
        icon: 'icon-jichushezhi',
        roles: ['*'],
        hideInMenu: false
      }
    }
  ]
};

export default Approval;
