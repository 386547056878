/*
 * @Author: 陈俊升 761723995@qq.com
 * @Date: 2023-11-21 10:29:32
 * @LastEditors: 陈俊升 761723995@qq.com
 * @LastEditTime: 2023-11-21 13:32:38
 * @FilePath: /hr_new_vue3/src/store/modules/management/contract/index.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/* 储存考核工具kpi、bpi等数据的store */
import { defineStore } from 'pinia';

const useContractStore = defineStore('contractStore', {
  state: () => ({
    waitSignedContractData: {
      baseInfoData: {
        name: '',
        enterprise: 1,
        templateId: undefined,
        templateName: '',
        attachmentIds: [],
        attachmentName: [],
        remark: ''
      },
      designData: {
        text: '',
        codes: []
      },
      pageHeaderSettingData: {
        style: 1,
        enterpriseName: '',
        logo: ''
      },
      fileSettingData: {}
    }
  }),
  getters: {
    // 返回考核评分计算
    getCalculate(state): number {
      return state.calculate;
    },
    // 返回评分上限规则
    geUpperLimit(state) {
      return state.upperLimit;
    },
    // 返回评分上限分值
    getUpperScore(state) {
      return state.upperScore;
    },
    // 获取当前选中的id
    getCurrId(state) {
      return state.currId;
    }
  },

  actions: {
    setBaseInfo(data: any) {
      this.waitSignedContractData.baseInfoData = data;
    },

    reset() {
      this.waitSignedContractData = {
        baseInfoData: {
          name: '',
          enterprise: 1,
          templateId: undefined,
          templateName: '',
          attachmentIds: [],
          attachmentName: [],
          remark: ''
        },
        designData: {
          text: '',
          codes: []
        },
        pageHeaderSettingData: {
          style: 1,
          enterpriseName: '',
          logo: ''
        },
        fileSettingData: {}
      };
    }
  }
});

export default useContractStore;
