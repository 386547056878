import { defineStore } from 'pinia';
import router from '@/router';
// import {}
// import {
//   login as userLogin,
//   logout as userLogout,
//   getUserInfo,
// } from '@/api/user';
import { DEFAULT_LAYOUT } from '@/router/routes/base';
// import { GetRouters } from '@/api/menu';
import { split } from 'lodash';
import { useUserStore } from '@/store';

// function filterAsyncRouter(
//   asyncRouterMap: any,
//   lastRouter = false,
//   type = false
// ) {
//   let MenuList: any = [];
//   let childMenulist: any = [];
//   asyncRouterMap?.forEach((item: any, index: any) => {
//     if (item.menuId === 3 && item.node === null) return;
//     if (!item.parentID) {
//       if (item.node) {
//         // eslint-disable-next-line no-use-before-define
//         item.node = filterChildren(item.node, '', item.path);
//       }
//       const {
//         component,
//         icon,
//         isCache,
//         isFrame,
//         menuName,
//         menuType,
//         orderNum,
//         parentID,
//         path,
//         perms,
//         query,
//         remark,
//         status,
//         visible,
//         menuId,
//         name,
//         node,
//       } = item;
//       const menuData: any = {
//         path: `/${path}`,
//         name: path,
//         component: DEFAULT_LAYOUT,
//         meta: {
//           locale: menuName,
//           hideInMenu: visible === 1 ? false : true,
//           icon,
//         },
//         children: node,
//       };
//       MenuList.push({ ...menuData });
//     }
//   });
//   return MenuList;
// }

const modules = import.meta.glob('@/views/client-side/**/*');

const useRouterStore = defineStore('permission', {
  state: () => ({
    routes: [],
    addRoutes: [],
    MenuList: [],
    aRouterList: [],
    apiList: ['contract-signings']
  }),

  actions: {
    // setSidebarRouters(router: any) {
    //   this.routes = router;
    //   this.addRoutes = router;
    //   sessionStorage.setItem('SideBarRouters', JSON.stringify(router));
    // },
    // async generateRoutes() {
    //   const UserStore = useUserStore();
    //   const id = Number(sessionStorage.getItem('userId'));
    //   const res = await getUserInfo({ id });
    //   UserStore.setInfo(res);
    //   // eslint-disable-next-line no-use-before-define
    //   if (res.menu === null) {
    //     res.menu = [];
    //   }
    //   const SidebarData = filterAsyncRouter(res.menu);
    //   this.setSidebarRouters(SidebarData);
    //   this.aRouterList = SidebarData?.map((item: any) => {
    //     // eslint-disable-next-line no-use-before-define
    //     item.children = filterRouterChildren(item.children);
    //     return item;
    //   });
    //   sessionStorage.setItem('DynamicRoute', JSON.stringify(this.aRouterList));
    //   return this.aRouterList;
    // }
  }
});

// function filterChildren(
//   childrenMap: any,
//   lastRouter: any,
//   ParentNode?: string
// ) {
//   let children: any = [];
//   childrenMap = childrenMap ? childrenMap : [];
//   if (!childrenMap.length) return [];
//   childrenMap?.forEach((el: any, index: number) => {
//     el.name = el.path;
//     el.path = `/${ParentNode}/${el.path}`;
//     el.component = modules[`${el.component}`];
//     el.meta = {
//       locale: el.menuName,
//       hideInMenu: el.visible === 1 ? false : true,
//       icon: el.icon
//     };
//     el.children = el.node;
//     delete el.node;
//     if (el.children && el.children.length) {
//       // eslint-disable-next-line no-use-before-define
//       filterChildrenNode(el.children, el.path);
//     }
//     children.push({ ...el });
//   });
//   return children;
// }

// function filterChildrenNode(data: any, aPath: string) {
//   data?.forEach((c) => {
//     c.component = modules[`${c.component}`];
//     c.name = c.path.split('/').pop();
//     c.path = aPath + '/' + c.path;
//     c.meta = {
//       locale: c.menuName,
//       hideInMenu: c.visible === 1 ? false : true,
//       icon: c.icon
//     };
//     c.children = c.node;
//     if (c.children && c.children.length) {
//       filterChildrenNode(c.children, c.path);
//     }
//   });
// }

function filterRouterChildren(list: any) {
  const res: any = [];
  list?.forEach((el: any) => {
    res.push(el);
    // eslint-disable-next-line no-unused-expressions
    el.children && res.push(...filterRouterChildren(el.children));
  });
  return res;
}

export default useRouterStore;
