/* 配置一些通用页面路由 */

import { REDIRECT_ROUTE_NAME } from '@/router/constants';
import { AppRouteRecordRaw } from './types';

export const DEFAULT_LAYOUT = () => import('@/layout/defaultLayout.vue');

export const REDIRECT_MAIN: AppRouteRecordRaw = {
  path: '/redirect',
  name: 'redirectWrapper',
  component: DEFAULT_LAYOUT,
  meta: {
    requiresAuth: true,
    hideInMenu: true
  },
  children: [
    {
      path: '/redirect/:path',
      name: REDIRECT_ROUTE_NAME,
      component: () => import('@/views/Base/Redirect/index.vue'),
      meta: {
        requiresAuth: true,
        hideInMenu: true
      }
    }
  ]
};

export const NOT_FOUND_ROUTE = {
  path: '/:pathMatch(.*)*',
  name: 'notFound',
  component: () => import('@/views/Base/NotFound/index.vue')
};
