//封装定时localStorage

//保存localStorage
export const SetTiming = (
  key: string, //localStorage的key
  value: any, //localStorage的value
  millisecond?: number // 保存的时间,超出时间无法返回，单位秒
) => {
  if (value) {
    const overtime = millisecond
      ? new Date().getTime() + millisecond * 1000
      : undefined;
    localStorage.setItem(
      key,
      JSON.stringify({
        value,
        overtime
      })
    );
  } else {
    localStorage.setItem(key, '');
  }
};

//获取localStorage
export const GetTiming = (key: string) => {
  const storeData = localStorage.getItem(key);
  if (storeData) {
    const nowTime = new Date().getTime();
    const obj = JSON.parse(storeData);
    if (obj.overtime) {
      return obj.overtime > nowTime ? obj.value : undefined;
    } else {
      return obj.value;
    }
  }
};
