/* 储存组织机构管理相关子路由（不显示在侧边导航栏的路由） */

const organizationalPositionSubRoutes: any = [
  /*标准职位库详情 */
  {
    path: 'positionStandardFileDetail',
    name: 'PositionStandardFileDetail',
    component: () =>
      import(
        '@/views/Management/Organization/PositionManagement/SystemStandManagement/Management/Components/MainSide/FileDetail/index.vue'
      ),
    meta: {
      locale: '标准职位库详情',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  },
  /* 职位档案详情 */
  {
    path: 'positionFileDetail',
    name: 'PositionFileDetail',
    component: () =>
      import(
        '@/views/Management/Organization/PositionManagement/SystemManagement/Components/Management/Components/MainSide/FileDetail/index.vue'
      ),
    meta: {
      locale: '职位档案详情',
      requiresAuth: true,
      roles: ['*'],
      hideInMenu: true
    }
  }
];

export default organizationalPositionSubRoutes;
