import { getMessageCenter } from '@/apis/notice/index';

const useStore = defineStore('notice', {
  state: () => {
    return {
      MessagTotal: 0 //成功数量
    };
  },
  actions: {
    async getMessagTotal() {
      const userId = Number(localStorage.getItem('userId'));
      nextTick(async () => {
        await getMessageCenter({
          pageNumber: 0,
          pageSize: -1,
          userId,
          type: 2
        });
        return;
        const { content, pageable } = await getMessageCenter({
          pageNumber: 0,
          pageSize: -1,
          userId,
          type: 2
        });
        this.MessagTotal = pageable.totalItems;
      });
    }
  }
});

export default useStore;
